
let reports = {
	"mental-health": {
		title: "Mental Health Report",
		description: "International gold standard measures of life satisfaction, hope, anxiety, depression, coping and positive learners' mindset.",
		image: "mental-health.svg",
		url: "/data/student/pdf-mentalhealth/?m=0,0"
	},
	"annual-outcomes": {
		title: "TOMS",
		description: "International gold standard measures of life satisfaction, hope, anxiety, depression and coping",
		image: "mental-health.svg",
		url: "/data/student/pdf-annualoutcomes/?m=0,0"
	},
	"swat": {
		title: "School Wellbeing Audit Tool",
		description: "SWAT Survey Report",
		image: "gemfinder.svg",
		url: "/data/swat/pdf-swat/?m=0,0"
	},
	"snapshot": {
		title: "Snapshot Report",
		description: "Student responses to all items, in percentage terms, colour coded alongside national norms.",
		image: "snapshot.svg",
		url: "/data/student/pdf-snapshot/?m=0,0"
	},
	"pdf-scatterplots": {
		title: "Scatterplot Report",
		description: "Visually review scores of resilience, healthy-mind and ready-to-learn among your students.",
		image: "mindset.svg",
		url: "/data/student/pdf-scatterplots/?m=0,0"
	},
	"executive-summary": {
		title: "Executive Summary Report",
		description: "Compare year levels in cross-sectional, longitudinal, or time-lag format alongside national norms.",
		image: "executive-summary.svg",
		url: "/data/student/executive-summary/?m=0,0,3"
	},
	"comments": {
		title: "Comments Report",
		description: "Qualitative student voice data.",
		image: "comments.svg",
		url: "/data/student/comments/?m=0,1"
	},
	"gemfinder": {
		title: "Gemfinder",
		description: "",
		image: "gemfinder.svg",
		url: "/data/student/gemfinder/?m=0,1"
	},
	"overview": {
		title: "Overview Report",
		description: "Understand the what, why, where and who of bullying among your students.",
		image: "overview.svg",
		url: "/data/student/pdf-overview/?m=1,0"
	},

/* 	"snapshot-short": {
		title: "Snapshot Report",
		description: "Student responses to all items, in percentage terms, colour coded alongside national norms. Includes cross-sectional tables by gender.",
		image: "snapshot.svg",
		url: "/data/student/pdf-snapshot-short/?m=1,0"
	},
	"mindset-short": {
		title: "Positive Learners Mindset",
		description: "Visually review levels of anxiety and disengagement among your students.",
		image: "mindset.svg",
		url: "/data/student/pdf-mindset/?m=1,0"
	},
	"executive-summary-short": {
		title: "Executive Summary Report",
		description: "Compare year levels in cross-sectional, longitudinal, or time-lag format alongside national norms.",
		image: "executive-summary.svg",
		url: "/data/student/executive-summary-short/?m=1,0,2"
	},
	"comments-short": {
		title: "Comments Report",
		description: "Qualitative student voice data.",
		image: "comments.svg",
		url: "/data/student/comments/?m=1,1"
	}, */

	"comments-no-bullying": {
		title: "Comments Report",
		description: "Qualitative student voice data.",
		image: "comments.svg",
		url: "/data/student/b21/?m=1,1,2"
	},

	"snapshot-staff": {
		title: "Snapshot Report",
		description: "Staff responses to all items, in percentage terms, colour coded alongside national norms. Includes cross-sectional tables by gender.",
		image: "snapshot.svg",
		url: "/data/staff/pdf-snapshot/?m=2,0"
	},
	"executive-summary-staff": {
		title: "Executive Summary Report",
		description: "Compare year levels in cross-sectional or time-lag format alongside national norms.",
		image: "executive-summary.svg",
		url: "/data/staff/executive-summary/?m=2,0,1"
	},
	"comments-staff": {
		title: "Comments Report",
		description: "Qualitative staff voice data.",
		image: "comments.svg",
		url: "/data/staff/rn50/?m=2,1,2"
	},

	"snapshot-parent": {
		title: "Snapshot Report",
		description: "Parent/Caregiver responses to all items, in percentage terms, colour coded alongside national norms. Includes cross-sectional tables by gender.",
		image: "snapshot.svg",
		url: "/data/parent/pdf-snapshot/?m=3,0"
	},
	"executive-summary-parent": {
		title: "Executive Summary Report",
		description: "Compare year levels in cross-sectional or time-lag format alongside national norms.",
		image: "executive-summary.svg",
		url: "/data/parent/executive-summary/?m=3,0,1"
	},
	"comments-parent": {
		title: "Comments Report",
		description: "Qualitative parent/caregiver voice data.",
		image: "comments.svg",
		url: "/data/parent/rn200/?m=3,1,2"
	},

	"snapshot-ipe-mi7": {
		title: "Snapshot Report",
		description: "Student responses to all items, in percentage terms, colour coded alongside national norms. Includes cross-sectional tables by gender.",
		image: "snapshot.svg",
		url: "/data/student/pdf-snapshot-ipe-mi7/?m=0,0"
	},
	"executive-summary-ipe-mi7": {
		title: "Executive Summary Report",
		description: "Compare year levels in cross-sectional or time-lag format alongside national norms.",
		image: "executive-summary.svg",
		url: "/data/student/executive-summary-ipe-mi7/?m=0,0,1"
	},
	"comments-ipe-mi7": {
		title: "Comments Report",
		description: "Qualitative student voice data.",
		image: "comments.svg",
		url: "/data/student/ipe43/?m=0,1,2"
	},

	"snapshot-learning-curve-wellbeing": {
		title: "Snapshot Report",
		description: "Student responses to all items, in percentage terms, colour coded alongside national norms. Includes cross-sectional tables by gender.",
		image: "snapshot.svg",
		url: "/data/student/pdf-snapshot-learning-curve-wellbeing/?m=0,0"
	},
	"executive-summary-learning-curve-wellbeing": {
		title: "Executive Summary Report",
		description: "Compare year levels in cross-sectional or time-lag format alongside national norms.",
		image: "executive-summary.svg",
		url: "/data/student/executive-summary-learning-curve-wellbeing/?m=0,0,1"
	},
	"overview-learning-curve": {
		title: "Bullying Overview Report",
		description: "Understand the what, where, why, and who of bullying at your school.",
		image: "overview.svg",
		url: "/data/student/pdf-overview-learning-curve/?m=0,0"
	},
}

export default reports;