exports.qs = {
	cantril: {
		type: "likert",
		key: "cantril",
		abbr: "life satisfaction",
		label: "at 5 or higher on an 8-rung ladder of life satisfaction",
		text: "Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for you and the bottom represents the worst possible life for you. On which step of the ladder do you feel you stand at this time?",
		responses: [
			"1 (worst possible life)",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8 (best possible life)",
		],
		bins: [1, 2, 3, 4, 5, 6, 7, 8],
		yMin: 1,
		yMax: 8,
		threshold: 4,
	},
	cantril2: {
		type: "likert",
		key: "cantril2",
		abbr: "job",
		label: "at 5 or higher on an 8-rung ladder of job satisfaction",
		text: "Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?",
		responses: [
			"1 (extremely dissatisfied)",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8 (very satisfied)",
		],
		bins: [1, 2, 3, 4, 5, 6, 7, 8],
		yMin: 1,
		yMax: 8,
		threshold: 4,
	},
	cantril3: {
		type: "likert",
		key: "cantril3",
		abbr: "workplace",
		label: "at 5 or higher on an 8-rung ladder of workplace satisfaction",
		text: "Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?",
		responses: [
			"1 (extremely dissatisfied)",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8 (very satisfied)",
		],
		bins: [1, 2, 3, 4, 5, 6, 7, 8],
		yMin: 1,
		yMax: 8,
		threshold: 4,
	},
	ry2: {
		type: "likert",
		key: "ry2",
		abbr: "family support",
		label: "love and support from family",
		text: "I get love and support from my family.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry3: {
		type: "likert",
		key: "ry3",
		abbr: "teacher who cares",
		label: "teacher at school who cares",
		text: "I have a teacher at my school who cares about me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry4: {
		type: "likert",
		key: "ry4",
		abbr: "teacher who encourages",
		label: "teachers who encourage",
		text: "I have at least one teacher who encourages me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry5: {
		type: "likert",
		key: "ry5",
		abbr: "parents/caregivers  who encourage",
		label: "parents/caregivers who encourage me",
		text: "I have parents/caregivers who encourage me to do well.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun5: {
		type: "likert",
		key: "sun5",
		abbr: "time with friends",
		label: "spend time with friends",
		text: "I spend time with friends.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry6: {
		type: "likert",
		key: "ry6",
		abbr: "one good friend",
		label: "at least one good friend at school",
		text: "I have at least one good friend at school.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry7: {
		type: "likert",
		key: "ry7",
		abbr: "adult role models",
		label: "adults who set a good example",
		text: "I have adults who set good examples for me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry27: {
		type: "likert",
		key: "ry27",
		abbr: "adult to talk to",
		label: "adult in my life who can talk to",
		text: "I have an adult in my life who I can talk to about my worries.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry8: {
		type: "likert",
		key: "ry8",
		abbr: "parent who listens",
		label: "parent/carer who listens to me",
		text: "I have a parent/carer who listens to me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry9: {
		type: "likert",
		key: "ry9",
		abbr: "keeping friends",
		label: "good at keeping friends",
		text: "I am good at keeping friends.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry10: {
		type: "likert",
		key: "ry10",
		abbr: "get along with others",
		label: "get along with people who are different from me",
		text: "get along with people who are different from me",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry29: {
		type: "likert",
		key: "ry29",
		abbr: "friends can disagree",
		label: "can disagree about things and still be friends",
		text: "My friends and I can disagree about things and still be friends.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry11: {
		type: "likert",
		key: "ry11",
		abbr: "can talk if upset",
		label: "can talk about things if they upset me",
		text: "I can talk about things if they upset me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry12: {
		type: "likert",
		key: "ry12",
		abbr: "keep out of trouble",
		label: "find it easy to keep out of trouble",
		text: "I find it easy to keep out of trouble.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	chs1: {
		type: "likert",
		key: "chs1",
		abbr: "doing well",
		label: "think I am doing pretty well",
		text: "In most situations I think I am doing pretty well.",
		responses: [
			"None of the time",
			"A little of the time",
			"Some of the time",
			"A lot of the time",
			"Most of the time",
			"All the time",
		],
		bins: [1, 2, 3, 4, 5, 6],
		yMin: 1,
		yMax: 6,
		threshold: 2,
	},
	chs2: {
		type: "likert",
		key: "chs2",
		abbr: "can think of ways",
		label: "can think of many ways to get important things",
		text: "In most situations I can think of many ways to get things in life that are most important to me.",
		responses: [
			"None of the time",
			"A little of the time",
			"Some of the time",
			"A lot of the time",
			"Most of the time",
			"All the time",
		],
		bins: [1, 2, 3, 4, 5, 6],
		yMin: 1,
		yMax: 6,
		threshold: 2,
	},
	chs3: {
		type: "likert",
		key: "chs3",
		abbr: "just as well",
		label: "doing as well as other kids",
		text: "In most situations I am doing just as well as other kids my age.",
		responses: [
			"None of the time",
			"A little of the time",
			"Some of the time",
			"A lot of the time",
			"Most of the time",
			"All the time",
		],
		bins: [1, 2, 3, 4, 5, 6],
		yMin: 1,
		yMax: 6,
		threshold: 2,
	},
	ry13: {
		type: "likert",
		key: "ry13",
		abbr: "home",
		label: "feel safe at home",
		text: "I feel safe at home.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry14: {
		type: "likert",
		key: "ry14",
		abbr: "school",
		label: "feel safe at school",
		text: "feel safe at school",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry15: {
		type: "likert",
		key: "ry15",
		abbr: "neighbourhood",
		label: "feel safe in the area where I live",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry30: {
		type: "likert",
		key: "ry30",
		abbr: "clear rules",
		label: "school gives students clear rules",
		text: "My school gives students clear rules.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry31: {
		type: "likert",
		key: "ry31",
		abbr: "rule enforcement",
		label: "school enforces rules fairly",
		text: "My school enforces rules fairly.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun11: {
		type: "likert",
		key: "sun11",
		abbr: "bullied",
		label: "rarely bullied at school",
		text: "In the last school term I have been bullied at school.",
		responses: ["Daily", "Weekly", "Once a term", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe72: {
		type: "likert",
		key: "ipe72",
		abbr: "bullied",
		label: "not bullied at school",
		text: "In the last school semester I have been bullied at school.",
		responses: ["Daily", "Weekly", "Once a semester", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun12: {
		type: "likert",
		key: "sun12",
		abbr: "bullied online",
		label: "rarely bullied online",
		text: "In the last school term I have been bullied online.",
		responses: ["Daily", "Weekly", "Once a term", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe73: {
		type: "likert",
		key: "ipe73",
		abbr: "bullied online",
		label: "not bullied online",
		text: "In the last school semester I have been bullied online.",
		responses: ["Daily", "Weekly", "Once a semester", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cyb1: {
		type: "likert",
		key: "cyb1",
		abbr: "overnight messaging",
		label: "not texting online between 10pm/midnight and 6am",
		text: "During the last week, how many times have you sent or received messages between the hours of 10pm at night and 6am in the morning?",
		responses: ["5 or more times", "3 or 4 times", "Once or twice", "None"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cyb4: {
		type: "likert",
		key: "cyb4",
		abbr: "replied in anger",
		text: "During last month, how many times have you replied in anger to a message or post online?",
		responses: ["5 or more times", "3 or 4 times", "Once or twice", "None"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cyb8: {
		type: "likert",
		key: "cyb8",
		abbr: "send regret",
		text: "During last month, how many times have you sent a message or made a post online you wish you hadn't?",
		responses: ["5 or more times", "3 or 4 times", "Once or twice", "None"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cyb9: {
		type: "likert",
		key: "cyb9",
		abbr: "cruel sharing",
		text: "During last month, how many times have you shared a mean or cruel online message or post with others?",
		responses: ["5 or more times", "3 or 4 times", "Once or twice", "None"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun4: {
		type: "likert",
		key: "sun4",
		abbr: "eat well",
		label: "eat well to stay well",
		text: "I eat well to stay well.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun3: {
		type: "likert",
		key: "sun3",
		abbr: "sleep",
		label: "get at least 8 hours of sleep most nights",
		text: "I get at least 8 hours of sleep most nights.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun1: {
		type: "likert",
		key: "sun1",
		abbr: "keep fit",
		label: "keep physically fit",
		text: "I keep myself fit.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ph1: {
		type: "likert",
		key: "ph1",
		abbr: "anxious",
		label: "feeling nervous, anxious, or on edge",
		text: "feeling nervous, anxious, or on edge",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ph2: {
		type: "likert",
		key: "ph2",
		abbr: "worrying",
		label: "not being able to stop or control worrying",
		text: "not being able to stop or control worrying",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ph3: {
		type: "likert",
		key: "ph3",
		abbr: "depressed",
		label: "not feeling down, depressed, or hopeless",
		text: "Feeling down, depressed or hopeless.",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ph4: {
		type: "likert",
		key: "ph4",
		abbr: "little interest",
		label: "take interest or pleasure in doing things",
		text: "Little interest or pleasure in doing things.",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	phq3: {
		type: "likert",
		key: "phq3",
		abbr: "sleep",
		label: "not having trouble sleeping or sleeping too much",
		text: "Trouble falling asleep, staying asleep, or sleeping too much.",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	phq4: {
		type: "likert",
		key: "phq4",
		abbr: "eating",
		label: "no poor appetite, weight loss or overeating",
		text: "Poor appetite, weight loss, or overeating.",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	phq5: {
		type: "likert",
		key: "phq5",
		abbr: "energy levels",
		label: "not feeling tired or low on energy",
		text: "Feeling tired, or having little energy.",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	phq7: {
		type: "likert",
		key: "phq7",
		abbr: "concentration",
		label: "no trouble concentrating",
		text: "Trouble concentrating on things like school work, reading, or watching TV.",
		responses: [
			"Nearly every day",
			"More than half the days",
			"Several days",
			"Not at all",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cop1: {
		type: "likert",
		key: "cop1",
		abbr: "avoiding",
		label: "not avoiding responding to problems",
		text: "When I have a problem, I avoid doing anything about it.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cop2: {
		type: "likert",
		key: "cop2",
		abbr: "wishing",
		label: "not wishing problems go away",
		text: "When I have a problem, I just wish it would go away.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cop3: {
		type: "likert",
		key: "cop3",
		abbr: "blaming self",
		label: "not blaming self when bad things happen",
		text: "When bad things happen, I usually blame myself.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	cop4: {
		type: "likert",
		key: "cop4",
		abbr: "talk to others",
		label: "talk to others when bad things happen",
		text: "When bad things happen, I talk to others about my problem.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry16: {
		type: "likert",
		key: "ry16",
		abbr: "read for fun",
		label: "read for fun",
		text: "I read for fun.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry17: {
		type: "likert",
		key: "ry17",
		abbr: "effort",
		label: "try hard at school",
		text: "I try hard at school.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry18: {
		type: "likert",
		key: "ry18",
		abbr: "care",
		label: "care about my school",
		text: "I care about my school.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry19: {
		type: "likert",
		key: "ry19",
		abbr: "homework",
		label: "do my homework",
		text: "I do my homework.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry20: {
		type: "likert",
		key: "ry20",
		abbr: "enjoyment",
		label: "enjoy learning new things at school",
		text: "I enjoy learning new things at school.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry28: {
		type: "likert",
		key: "ry28",
		abbr: "fun",
		label: "have fun at school",
		text: "I have fun at school.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry21: {
		type: "likert",
		key: "ry21",
		abbr: "feel good",
		label: "feel good about myself",
		text: "feel good about myself",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	chs4: {
		type: "likert",
		key: "chs4",
		abbr: "problem-solving",
		label: "can come up with ways to solve problems",
		text: "In most situations when I have a problem, I can come up with lots of ways to solve it.",
		responses: [
			"None of the time",
			"A little of the time",
			"Some of the time",
			"A lot of the time",
			"Most of the time",
			"All the time",
		],
		bins: [1, 2, 3, 4, 5, 6],
		yMin: 1,
		yMax: 6,
		threshold: 2,
	},
	chs5: {
		type: "likert",
		key: "chs5",
		abbr: "experience",
		label: "past experiences will help me in the future",
		text: "In most situations I think the things I have done in the past will help me in the future.",
		responses: [
			"None of the time",
			"A little of the time",
			"Some of the time",
			"A lot of the time",
			"Most of the time",
			"All the time",
		],
		bins: [1, 2, 3, 4, 5, 6],
		yMin: 1,
		yMax: 6,
		threshold: 2,
	},
	chs6: {
		type: "likert",
		key: "chs6",
		abbr: "persistence",
		label: "know that I can find a way to solve the problem",
		text: "In most situations even when others want to quit, I know that I can find ways to solve the problem.",
		responses: [
			"None of the time",
			"A little of the time",
			"Some of the time",
			"A lot of the time",
			"Most of the time",
			"All the time",
		],
		bins: [1, 2, 3, 4, 5, 6],
		yMin: 1,
		yMax: 6,
		threshold: 2,
	},
	tru1: {
		type: "likert",
		key: "tru1",
		abbr: "trust",
		label: "able to trust other people",
		text: "I am able to trust people.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	tru2: {
		type: "likert",
		key: "tru2",
		abbr: "forgiveness",
		label: "am trustworthy",
		text: "I am trustworthy.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	for1: {
		type: "likert",
		key: "for1",
		abbr: "responsibility",
		label: "forgive others who are mean to me",
		text: "I forgive others who are mean to me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	for2: {
		type: "likert",
		key: "for2",
		abbr: "trustworthy",
		label: "forgive myself when I mess up",
		text: "I forgive myself when I mess up.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		scaletype: "likert",
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry23: {
		type: "likert",
		key: "ry23",
		abbr: "self-forgiveness",
		label: "take responsibility for my actions",
		text: "I take responsibility for my actions.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry24: {
		type: "likert",
		key: "ry24",
		abbr: "helping others",
		label: "think it is important to help other people",
		text: "I think it is important to help other people.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry22: {
		type: "likert",
		key: "ry22",
		abbr: "useful jobs",
		label: "given useful jobs at school",
		text: "I am given useful jobs at school.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry25: {
		type: "likert",
		key: "ry25",
		abbr: "extra-curricular",
		label: "involved in at least one extra-curricular",
		text: "I am involved with at least one activity outside of school such as a club, music, art, dance, sport, hobby or youth group.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry26: {
		type: "likert",
		key: "ry26",
		abbr: "give time to others",
		label: "give my time to help others",
		text: "give my time to help others",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry32: {
		type: "likert",
		key: "ry32",
		abbr: "vape",
		label: "do not vape",
		text: "I vape.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	aud1: {
		type: "likert",
		key: "aud1",
		abbr: "smoke",
		label: "do not smoke cigarettes",
		text: "I smoke cigarettes.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	aud2: {
		type: "likert",
		key: "aud2",
		abbr: "drink alcohol",
		label: "do not drink alcohol",
		text: "I drink alcohol.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	aud3: {
		type: "likert",
		key: "aud3",
		abbr: "illegal drugs",
		label: "do not use illegal drugs",
		text: "I use illegal drugs.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	gam1: {
		type: "likert",
		key: "gam1",
		abbr: "gamble online",
		label: "do not gamble online",
		text: "I gamble online.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	gam2: {
		type: "likert",
		key: "gam2",
		abbr: "online games",
		label: "not playing excessive online games",
		text: "I play online games.",
		responses: [
			"More than 4 hours each day",
			"2-4 hours each day",
			"Up to 2 hours each day",
			"Never",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sun9: {
		type: "likert",
		key: "sun9",
		abbr: "carry weapon",
		label: "have not carried a weapon to protect myself",
		text: "In the last school term I carried a weapon to protect myself.",
		responses: ["Daily", "Weekly", "Once a term", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	ipe74: {
		type: "likert",
		key: "ipe74",
		abbr: "carry weapon",
		label: "have not carried a weapon to protect myself",
		text: "In the last school semester I carried a weapon to protect myself.",
		responses: ["Daily", "Weekly", "Once a semester", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	sun10: {
		type: "likert",
		key: "sun10",
		abbr: "threaten others",
		label: "have not threatened to physically hurt someone",
		text: "In the last school term I threatened physically to hurt someone.",
		responses: ["Daily", "Weekly", "Once a term", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	ipe75: {
		type: "likert",
		key: "ipe75",
		abbr: "threaten others",
		label: "have not threatened to physically hurt someone",
		text: "In the last school semester I threatened physically to hurt someone.",
		responses: ["Daily", "Weekly", "Once a semester", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},

	be5_1: {
		type: "multi",
		key: "be5",
		label: "my body (hair/height/weight/skin colour)",
		opt: "1",
	},
	be5_2: {
		type: "multi",
		key: "be5",
		label: "the way I talk",
		opt: "2",
	},
	be5_3: {
		type: "multi",
		key: "be5",
		label: "the clothes I wear",
		opt: "3",
	},
	be5_4: {
		type: "multi",
		key: "be5",
		label: "my family or friends",
		opt: "4",
	},
	be5_5: {
		type: "multi",
		key: "be5",
		label: "my school results",
		opt: "5",
	},
	be5_6: {
		type: "multi",
		key: "be5",
		label: "my hobbies or sport",
		opt: "6",
	},
	be5_7: {
		type: "multi",
		key: "be5",
		label: "my ethnic background",
		opt: "7",
	},
	be5_9: {
		type: "multi",
		key: "be5",
		label: "my sexuality",
		opt: "9",
	},
	be5_8: {
		type: "multi",
		key: "be5",
		label: "my disability",
		opt: "8",
	},
	be5_0: {
		type: "multi",
		key: "be5",
		label: "other",
		opt: "0.0",
	},

	bw5_0: {
		type: "multi",
		key: "bw5",
		label: "other",
		opt: "0.0",
	},
	bw5_1: {
		type: "multi",
		key: "bw5",
		label: "their body (hair/height/weight/skin colour)",
		opt: "1",
	},
	bw5_2: {
		type: "multi",
		key: "bw5",
		label: "the way they talk",
		opt: "2",
	},
	bw5_3: {
		type: "multi",
		key: "bw5",
		label: "the clothes they wear",
		opt: "3",
	},
	bw5_4: {
		type: "multi",
		key: "bw5",
		label: "their family or friends",
		opt: "4",
	},
	bw5_5: {
		type: "multi",
		key: "bw5",
		label: "their school results",
		opt: "5",
	},
	bw5_6: {
		type: "multi",
		key: "bw5",
		label: "their hobbies or sport",
		opt: "6",
	},
	bw5_7: {
		type: "multi",
		key: "bw5",
		label: "their ethnic background",
		opt: "7",
	},
	bw5_9: {
		type: "multi",
		key: "bw5",
		label: "their sexuality",
		opt: "9",
	},
	bw5_8: {
		type: "multi",
		key: "bw5",
		label: "their disability",
		opt: "8",
	},
	bw5_99: {
		type: "multi",
		key: "bw5",
		label: "not sure",
		opt: "99",
	},

	be6_0: {
		type: "multi",
		key: "be6",
		label: "other",
		opt: "0.0",
	},
	be6_1: {
		type: "multi",
		key: "be6",
		label: "at school",
		opt: "1",
	},
	be6_2: {
		type: "multi",
		key: "be6",
		label: "on the way to school",
		opt: "2",
	},
	be6_3: {
		type: "multi",
		key: "be6",
		label: "on the way home from school",
		opt: "3",
	},
	be6_4: {
		type: "multi",
		key: "be6",
		label: "in the school yard",
		opt: "4",
	},
	be6_5: {
		type: "multi",
		key: "be6",
		label: "in the school buildings",
		opt: "5",
	},
	be6_61: {
		type: "multi",
		key: "be6",
		label: "in the classroom when the teacher was absent",
		opt: "61",
	},
	be6_62: {
		type: "multi",
		key: "be6",
		label: "in the classroom when the teacher was present",
		opt: "62",
	},
	be6_7: {
		type: "multi",
		key: "be6",
		label: "on public transport",
		opt: "7",
	},
	be6_8: {
		type: "multi",
		key: "be6",
		label: "by email/text/phone or social media",
		opt: "8",
	},

	bw6_0: {
		type: "multi",
		key: "bw6",
		label: "other",
		opt: "0.0",
	},
	bw6_1: {
		type: "multi",
		key: "bw6",
		label: "at school",
		opt: "1",
	},
	bw6_2: {
		type: "multi",
		key: "bw6",
		label: "on the way to school",
		opt: "2",
	},
	bw6_3: {
		type: "multi",
		key: "bw6",
		label: "on the way home from school",
		opt: "3",
	},
	bw6_4: {
		type: "multi",
		key: "bw6",
		label: "in the school yard",
		opt: "4",
	},
	bw6_5: {
		type: "multi",
		key: "bw6",
		label: "in the school buildings",
		opt: "5",
	},
	bw6_6: {
		type: "multi",
		key: "bw6",
		label: "in the classroom",
		opt: "6",
	},
	bw6_7: {
		type: "multi",
		key: "bw6",
		label: "on public transport",
		opt: "7",
	},
	bw6_8: {
		type: "multi",
		key: "bw6",
		label: "by email/text/phone or social media",
		opt: "8",
	},

	be7_0: {
		type: "multi",
		key: "be7",
		label: "other",
		opt: "0.0",
	},
	be7_1: {
		type: "multi",
		key: "be7",
		label: "someone from my school",
		opt: "1",
	},
	be7_2: {
		type: "multi",
		key: "be7",
		label: "someone not from my school",
		opt: "2",
	},
	be7_3: {
		type: "multi",
		key: "be7",
		label: "someone in my year level",
		opt: "3",
	},
	be7_4: {
		type: "multi",
		key: "be7",
		label: "a girl",
		opt: "4",
	},
	be7_5: {
		type: "multi",
		key: "be7",
		label: "two or more girls",
		opt: "5",
	},
	be7_6: {
		type: "multi",
		key: "be7",
		label: "a boy",
		opt: "6",
	},
	be7_7: {
		type: "multi",
		key: "be7",
		label: "two or more boys",
		opt: "7",
	},
	be7_8: {
		type: "multi",
		key: "be7",
		label: "a group of boys and girls",
		opt: "8",
	},
	be7_9: {
		type: "multi",
		key: "be7",
		label: "an adult",
		opt: "9",
	},

	bw7_0: {
		type: "multi",
		key: "bw7",
		label: "other",
		opt: "0.0",
	},
	bw7_1: {
		type: "multi",
		key: "bw7",
		label: "someone from my school",
		opt: "1",
	},
	bw7_2: {
		type: "multi",
		key: "bw7",
		label: "someone not from my school",
		opt: "2",
	},
	bw7_3: {
		type: "multi",
		key: "bw7",
		label: "someone in my year level",
		opt: "3",
	},
	bw7_4: {
		type: "multi",
		key: "bw7",
		label: "a girl",
		opt: "4",
	},
	bw7_5: {
		type: "multi",
		key: "bw7",
		label: "two or more girls",
		opt: "5",
	},
	bw7_6: {
		type: "multi",
		key: "bw7",
		label: "a boy",
		opt: "6",
	},
	bw7_7: {
		type: "multi",
		key: "bw7",
		label: "two or more boys",
		opt: "7",
	},
	bw7_8: {
		type: "multi",
		key: "bw7",
		label: "a group of boys and girls",
		opt: "8",
	},
	bw7_9: {
		type: "multi",
		key: "bw7",
		label: "an adult",
		opt: "9",
	},

	be8_0: {
		type: "multi",
		key: "be8",
		label: "other",
		opt: "0.0",
	},
	be8_1: {
		type: "multi",
		key: "be8",
		label: "I walked away",
		opt: "1",
	},
	be8_2: {
		type: "multi",
		key: "be8",
		label: "I was afraid or angry or upset",
		opt: "2",
	},
	be8_3: {
		type: "multi",
		key: "be8",
		label: "I laughed about it",
		opt: "3",
	},
	be8_4: {
		type: "multi",
		key: "be8",
		label: "I didn't tell anyone",
		opt: "4",
	},
	be8_5: {
		type: "multi",
		key: "be8",
		label: "I told a teacher",
		opt: "5",
	},
	be8_6: {
		type: "multi",
		key: "be8",
		label: "I told someone in my family",
		opt: "6",
	},
	be8_7: {
		type: "multi",
		key: "be8",
		label: "I told a friend",
		opt: "7",
	},
	be8_9: {
		type: "multi",
		key: "be8",
		label: "I said something to the bully",
		opt: "9",
	},
	be8_10: {
		type: "multi",
		key: "be8",
		label: "I contacted a helpline",
		opt: "10",
	},

	bw8_0: {
		type: "multi",
		key: "bw8",
		label: "other",
		opt: "0.0",
	},
	bw8_1: {
		type: "multi",
		key: "bw8",
		label: "I walked away",
		opt: "1",
	},
	bw8_2: {
		type: "multi",
		key: "bw8",
		label: "I was afraid or angry or upset",
		opt: "2",
	},
	bw8_3: {
		type: "multi",
		key: "bw8",
		label: "I laughed about it",
		opt: "3",
	},
	bw8_4: {
		type: "multi",
		key: "bw8",
		label: "I didn't tell anyone",
		opt: "4",
	},
	bw8_5: {
		type: "multi",
		key: "bw8",
		label: "I told a teacher",
		opt: "5",
	},
	bw8_6: {
		type: "multi",
		key: "bw8",
		label: "I told someone in my family",
		opt: "6",
	},
	bw8_7: {
		type: "multi",
		key: "bw8",
		label: "I told a friend",
		opt: "7",
	},
	bw8_8: {
		type: "multi",
		key: "bw8",
		label: "I tried to support the person who was bullied",
		opt: "8",
	},
	bw8_9: {
		type: "multi",
		key: "bw8",
		label: "I said something to the bully",
		opt: "9",
	},
	bw8_10: {
		type: "multi",
		key: "bw8",
		label: "I contacted a helpline",
		opt: "10",
	},

	b11_1: {
		type: "multi",
		key: "b11",
		label: "fair",
		opt: "1",
	},
	b11_2: {
		type: "multi",
		key: "b11",
		label: "unfair",
		opt: "2",
	},
	b11_3: {
		type: "multi",
		key: "b11",
		label: "clear",
		opt: "3",
	},
	b11_4: {
		type: "multi",
		key: "b11",
		label: "unclear",
		opt: "4",
	},
	b11_5: {
		type: "multi",
		key: "b11",
		label: "enforced fairly",
		opt: "5",
	},
	b11_6: {
		type: "multi",
		key: "b11",
		label: "enforced unfairly",
		opt: "6",
	},
	b11_7: {
		type: "multi",
		key: "b11",
		label: "enforced by some teachers but not all",
		opt: "7",
	},

	b12_0: {
		type: "multi",
		key: "b12",
		label: "None of the above.",
		opt: "0.0",
	},
	b12_1: {
		type: "multi",
		key: "b12",
		label: "Bullying is a problem for me.",
		opt: "1",
	},
	b12_2: {
		type: "multi",
		key: "b12",
		label: "If I tell a parent/carer about bullying they will help.",
		opt: "2",
	},
	b12_3: {
		type: "multi",
		key: "b12",
		label: "My teachers take bullying seriously.",
		opt: "3",
	},
	b12_4: {
		type: "multi",
		key: "b12",
		label: "I know bullying when I see it.",
		opt: "4",
	},
	b12_5: {
		type: "multi",
		key: "b12",
		label: "I sometimes bully other students.",
		opt: "5",
	},
	b12_6: {
		type: "multi",
		key: "b12",
		label: "I don't know if I am a bully or not.",
		opt: "6",
	},
	b12_7: {
		type: "multi",
		key: "b12",
		label: "Some students deserve to be bullied.",
		opt: "7",
	},
	b12_8: {
		type: "multi",
		key: "b12",
		label: "If I am bullied I know what to do.",
		opt: "8",
	},
	b12_9: {
		type: "multi",
		key: "b12",
		label: "I have missed school days because of bullying.",
		opt: "9",
	},

	b13_0: {
		type: "multi",
		key: "b13",
		label: "None of the above",
		opt: "0.0",
	},
	b13_1: {
		type: "multi",
		key: "b13",
		label: "Why people bully others",
		opt: "1",
	},
	b13_2: {
		type: "multi",
		key: "b13",
		label: "How to respond to being bullied",
		opt: "2",
	},
	b13_3: {
		type: "multi",
		key: "b13",
		label: "How to be safe online",
		opt: "3",
	},
	b13_4: {
		type: "multi",
		key: "b13",
		label: "How to support others who have been bullied",
		opt: "4",
	},
	b13_5: {
		type: "multi",
		key: "b13",
		label: "What support is available to people who are bullied",
		opt: "5",
	},

	tp1: {
		type: "likert",
		key: "tp1",
		abbr: "students- caring teacher",
		label: "students have a teacher at school who cares about them",
		text: "students have a teacher at school who cares about them",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp2: {
		type: "likert",
		key: "tp2",
		abbr: "students - encouraging teacher",
		label: "students have a teacher who encourages them",
		text: "students have a teacher who encourages them",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp3: {
		type: "likert",
		key: "tp3",
		abbr: "students - encouraging parent/caregiver",
		label: "students have parents/caregivers who encourage them to do well",
		text: "students have parents/caregivers who encourage them to do well",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp4: {
		type: "likert",
		key: "tp4",
		abbr: "students - feel safe",
		label: "students feel safe at school",
		text: "students feel safe at school",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp5: {
		type: "likert",
		key: "tp5",
		abbr: "students - sleep 8 hrs",
		label: "students get at least 8 hours sleep each night",
		text: "students get at least 8 hours sleep each night",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp6: {
		type: "likert",
		key: "tp6",
		abbr: "students - read for fun",
		label: "students read for fun",
		text: "students read for fun",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp7: {
		type: "likert",
		key: "tp7",
		abbr: "students - trying hard",
		label: "students try hard at school",
		text: "students try hard at school",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp8: {
		type: "likert",
		key: "tp8",
		abbr: "students - care about school",
		label: "students care about this school",
		text: "students care about this school",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp9: {
		type: "likert",
		key: "tp9",
		abbr: "students - enjoy learning",
		label: "students enjoy learning new things at school",
		text: "students enjoy learning new things at school",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp10: {
		type: "likert",
		key: "tp10",
		abbr: "students - useful jobs",
		label: "students are given useful jobs at school",
		text: "students are given useful jobs at school",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp11: {
		type: "likert",
		key: "tp11",
		abbr: "students - fun at school",
		label: "students have fun at school",
		text: "students have fun at school",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp12: {
		type: "likert",
		key: "tp12",
		abbr: "students - clear school rules",
		label: "students believe our school gives students clear rules",
		text: "students believe our school gives students clear rules",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp13: {
		type: "likert",
		key: "tp13",
		abbr: "students - school enforces rules fairly",
		label: "students believe our school enforces rules fairly",
		text: "students believe our school enforces rules fairly",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	tp14: {
		type: "likert",
		key: "tp14",
		abbr: "students - bullied weekly",
		label: "students are not bullied at school in a typical week",
		text: "students are not bullied at school in a typical week",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [5, 4, 3, 2, 1],
		yMin: 1,
		yMax: 5,
		threshold: 3,
		flipped: true,
	},
	tp15: {
		type: "likert",
		key: "tp15",
		abbr: "students - left out / ignored",
		label: "students are not left out of a group of friends on purpose, not ignored, or not had rumours spread about them in a typical week",
		text: "students are not left out of a group of friends on purpose, not ignored, or not had rumours spread about them in a typical week",
		responses: ["Between 20% and 0%", "Between 40% and 21%", "Between 60% and 41%", "Between 80% and 61%", "Between 100% and 81%"],
		bins: [5, 4, 3, 2, 1],
		yMin: 1,
		yMax: 5,
		threshold: 3,
		flipped: true,
	},
	rn1: {
		type: "likert",
		key: "rn1",
		abbr: "growth",
		label: "can grow personally and professionally",
		text: "I can grow personally and professionally working here.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn2: {
		type: "likert",
		key: "rn2",
		abbr: "love work",
		label: "love coming to work most days",
		text: "I love coming to work most days.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn3: {
		type: "likert",
		key: "rn3",
		abbr: "pursue goals",
		label: "energetically pursue personal and work goals",
		text: "I energetically pursue both my personal and work goals.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn4: {
		type: "likert",
		key: "rn4",
		abbr: "psychologically healthy",
		label: "I would describe my workplace as being psychologically healthy",
		text: "I would describe my workplace as being psychologically healthy",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn5: {
		type: "likert",
		key: "rn5",
		abbr: "work-life balance",
		label: "can balance demands of work and personal life",
		text: "I am able to reasonably balance the demands of work and personal life.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn6: {
		type: "likert",
		key: "rn6",
		abbr: "family support",
		label: "have support of family/friends when work is hard",
		text: "I can rely on support from my family and friends when things get hard at work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn7: {
		type: "likert",
		key: "rn7",
		abbr: "sense of humour",
		label: "manage to keep sense of humour at work",
		text: "I generally manage to keep my sense of humour at work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ahs1: {
		type: "likert",
		key: "ahs1",
		abbr: "bad situation",
		label: "can think of many ways to get out of a bad situation",
		text: "I can think of many ways to get out of a bad situation.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ahs3: {
		type: "likert",
		key: "ahs3",
		abbr: "lots of ways",
		label: "There are lots of ways around any problem",
		text: "There are lots of ways around any problem",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ahs4: {
		type: "likert",
		key: "ahs4",
		abbr: "can think of ways",
		label: "can think of ways to get important things",
		text: "I can think of many ways to get the things in life that are most important to me.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ahs5: {
		type: "likert",
		key: "ahs5",
		abbr: "persistence",
		label: "know that I can find a way to solve the problem",
		text: "Even when others get discouraged, I know I can find a way to solve the problem.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	rn8: {
		type: "likert",
		key: "rn8",
		abbr: "expectations",
		label: "know exactly what is expected of me",
		text: "I know exactly what is expected of me in my job.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn9: {
		type: "likert",
		key: "rn9",
		abbr: "influence",
		label: "can influence decisions that are important to my work",
		text: "I can influence decisions that are important to my work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn10: {
		type: "likert",
		key: "rn10",
		abbr: "feel valued",
		label: "feel cared for and valued as a person",
		text: "I feel cared for and valued as a person at this school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn80: {
		type: "likert",
		key: "rn80",
		abbr: "feel valued",
		label: "feel cared for and valued as a person",
		text: "I feel cared for and valued as a person at work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn11: {
		type: "likert",
		key: "rn11",
		abbr: "work acknowledged",
		label: "feel my hard work is acknowledged",
		text: "I feel like my hard work is acknowledged.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn12: {
		type: "likert",
		key: "rn12",
		abbr: "job importance",
		label: "job is an important part of life",
		text: "My job is an important part of my life.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn13: {
		type: "likert",
		key: "rn13",
		abbr: "work-life balance",
		label: "work does not unduly interfere with home life",
		text: "My work does not unduly interfere with my home life in terms of time and energy.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn14: {
		type: "likert",
		key: "rn14",
		abbr: "great workplace",
		label: "find school a great place to work",
		text: "On the whole I find this school a great place to work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn90: {
		type: "likert",
		key: "rn90",
		abbr: "great workplace",
		label: "On the whole I find this a great place to work.",
		text: "On the whole I find this a great place to work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	rn15: {
		type: "likert",
		key: "rn15",
		abbr: "value alignment",
		label: "personal values generally align with school values",
		text: "My personal values generally align with the values of this school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn91: {
		type: "likert",
		key: "rn91",
		abbr: "value alignment",
		label: "personal values generally align with organisation values",
		text: "My personal values generally align with the organisation’s values.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn16: {
		type: "likert",
		key: "rn16",
		abbr: "upskilling",
		label: "school actively helps me improve my skills",
		text: "This school actively helps me to improve my skills.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn92: {
		type: "likert",
		key: "rn92",
		abbr: "upskilling",
		label: "organisation actively helps me improve my skills",
		text: "My organisation actively helps me to improve my skills.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn17: {
		type: "likert",
		key: "rn17",
		abbr: "autonomy",
		label: "given reasonable degree of autonomy",
		text: "I am given a reasonable degree of autonomy.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn18: {
		type: "likert",
		key: "rn18",
		abbr: "fulfilment",
		label: "job is satisfying and fulfilling",
		text: "I find my job is satisfying and fulfilling.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn19: {
		type: "likert",
		key: "rn19",
		abbr: "access to resources",
		label: "have access to resources I need to do my job well",
		text: "I have access to the resources I need to do my job well.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn20: {
		type: "likert",
		key: "rn20",
		abbr: "parent/caregiver respect",
		label: "parents/caregivers hold teaching in high regard",
		text: "The parents/caregivers at this school hold the teaching profession in high regard.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn93: {
		type: "likert",
		key: "rn93",
		abbr: "public respect",
		label: "public holds our organisation in high regard",
		text: "The general public seem to holds our our organisation in high regard.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn21: {
		type: "likert",
		key: "rn21",
		abbr: "leadership confidence",
		label: "confident of being supported by school leaders",
		text: "I am confident of being supported by school leaders if issues arise.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn94: {
		type: "likert",
		key: "rn94",
		abbr: "leadership confidence",
		label: "confident of being supported by manager",
		text: "I am confident of being supported by my manager if issues arise.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn22: {
		type: "likert",
		key: "rn22",
		abbr: "distress",
		label: "If I seemed distressed at work my manager would say or do something helpful.",
		text: "If I seemed distressed at work my manager would say or do something helpful.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn23: {
		type: "likert",
		key: "rn23",
		abbr: "overwhelm",
		label: "rarely feel overstressed",
		text: "I rarely feel overstressed by my work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn24: {
		type: "likert",
		key: "rn24",
		abbr: "stress minimisation",
		label: "school committed to minimising stress at work",
		text: "This school is committed to minimising unnecessary stress at work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn95: {
		type: "likert",
		key: "rn95",
		abbr: "stress minimisation",
		label: "organisation committed to minimising stress at work",
		text: "My organisation is committed to minimising unnecessary stress at work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn25: {
		type: "likert",
		key: "rn25",
		abbr: "meaning",
		label: "work I do is meaningful and important",
		text: "The work I do is meaningful and important.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn26: {
		type: "likert",
		key: "rn26",
		abbr: "enjoyment",
		label: "I enjoy engaging with students.",
		text: "I enjoy engaging with students.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn96: {
		type: "likert",
		key: "rn96",
		abbr: "enjoyment",
		label: "enjoy engaging with workmates and customers",
		text: "I enjoy engaging with my workmates and our customers.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	rn27: {
		type: "likert",
		key: "rn27",
		abbr: "school climate",
		label: "school climate is encouraging and supportive",
		text: "Overall, the school climate is encouraging and supportive.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn97: {
		type: "likert",
		key: "rn97",
		abbr: "work climate",
		label: "work climate is encouraging and supportive",
		text: "Overall, the work climate is encouraging and supportive.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn28: {
		type: "likert",
		key: "rn28",
		abbr: "taken seriously",
		label: "mental health and wellbeing of staff taken seriously",
		text: "The mental health and wellbeing of staff is taken seriously at this school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn98: {
		type: "likert",
		key: "rn98",
		abbr: "taken seriously",
		label: "mental health and wellbeing of staff taken seriously",
		text: "The psychological health and wellbeing of staff is taken seriously here.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn29: {
		type: "likert",
		key: "rn29",
		abbr: "belonging",
		label: "School staff have a strong sense of belonging.",
		text: "School staff have a strong sense of belonging.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn99: {
		type: "likert",
		key: "rn99",
		abbr: "belonging",
		label: "staff have strong sense of belonging",
		text: "Staff have a strong sense of belonging.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn30: {
		type: "likert",
		key: "rn30",
		abbr: "personal issues",
		label: "feel supported when dealing with personal issues",
		text: "I feel supported in my workplace when I am dealing with personal or family issues.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn31: {
		type: "likert",
		key: "rn31",
		abbr: "harassment",
		label: "not harassed bullied or threatened in last 12 months",
		text: "I have not been harassed, bullied or threatened at this school in the last 12 months.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn100: {
		type: "likert",
		key: "rn100",
		abbr: "harassment",
		label: "not harassed bullied or threatened in last 12 months",
		text: "I have not been harassed, bullied or threatened at work in the last 12 months.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn32: {
		type: "likert",
		key: "rn32",
		abbr: "good friends",
		label: "made good friends among colleagues",
		text: "I have made good friends with some of my colleagues.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn33: {
		type: "likert",
		key: "rn33",
		abbr: "entitled leave",
		label: "encouraged to take entitled breaks",
		text: "I am encouraged to take entextd breaks (lunchtime, sick leave, vacation time, parental leave, carers leave, personal leave, compassionate leave).",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn34: {
		type: "likert",
		key: "rn34",
		abbr: "people problems",
		label: 'workplace effectively handles "people problems"',
		text: "Our workplace effectively handles “people problems” among staff (e.g. sexual harassment, discrimination, threats of violence, physical intimidation, bullying, ongoing conflicts and quarrels, unpleasant gossip or slander).",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn35: {
		type: "likert",
		key: "rn35",
		abbr: "physical safety",
		label: "physical safety of staff taken seriously",
		text: "The physical safety of staff is taken seriously at this school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn101: {
		type: "likert",
		key: "rn101",
		abbr: "physical safety",
		label: "physical safety of staff taken seriously",
		text: "The physical safety of staff is taken seriously.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn36: {
		type: "likert",
		key: "rn36",
		abbr: "treated fairly",
		label: "staff treated fairly, impartially, justly",
		text: "All staff are treated fairly, impartially and justly at all times.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn37: {
		type: "likert",
		key: "rn37",
		abbr: "quality education",
		label: "students getting high quality education",
		text: "Students are getting a high quality education at this school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn102: {
		type: "likert",
		key: "rn102",
		abbr: "better outcomes",
		label: "organisation is working to produce better outcomes",
		text: "This organisation is working hard to produce better outcomes.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn38: {
		type: "likert",
		key: "rn38",
		abbr: "respect",
		label: "people treat each other with respect",
		text: "People treat each other with respect and consideration in our workplace.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn39: {
		type: "likert",
		key: "rn39",
		abbr: "trust",
		label: "trust between staff and school leaders",
		text: "There is trust between staff and school leaders.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn103: {
		type: "likert",
		key: "rn103",
		abbr: "trust",
		label: "trust between staff and managers",
		text: "There is trust between staff and our managers.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn40: {
		type: "likert",
		key: "rn40",
		abbr: "professional learning",
		label: "school supports me with Professional Learning",
		text: "This school supports me with Professional Learning that is relevant.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn104: {
		type: "likert",
		key: "rn104",
		abbr: "professional learning",
		label: "organisation supports me with Professional Learning",
		text: "This organisation supports me with Professional Learning that is relevant to me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn41: {
		type: "likert",
		key: "rn41",
		abbr: "suggestions",
		label: "able to make suggestions to school leaders",
		text: "I am able to make suggestions to school leaders that will be considered.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn105: {
		type: "likert",
		key: "rn105",
		abbr: "suggestions",
		label: "able to make suggestions to managers",
		text: "I am able to make suggestions to my managers and they will be considered.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn42: {
		type: "likert",
		key: "rn42",
		abbr: "well-informed",
		label: "staff well-informed about school's direction",
		text: "Staff are well informed about the school’s direction.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn106: {
		type: "likert",
		key: "rn106",
		abbr: "well-informed",
		label: "staff well-informed about organisation's direction",
		text: "Staff are well informed about this organisation’s direction.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn43: {
		type: "likert",
		key: "rn43",
		abbr: "recommend",
		label: "recommend this school as a place to work",
		text: "I would recommend this school as a place to work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn107: {
		type: "likert",
		key: "rn107",
		abbr: "recommend",
		label: "recommend this as a place to work",
		text: "I would recommend this as a place to work.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn44: {
		type: "likert",
		key: "rn44",
		abbr: "feedback",
		label: "person I report to provides helpful feedback",
		text: "The person I report to provides helpful feedback on my performance.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn108: {
		type: "likert",
		key: "rn108",
		abbr: "feedback",
		label: "supervisor provides helpful feedback",
		text: "My supervisor provides helpful feedback on my performance.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn45: {
		type: "likert",
		key: "rn45",
		abbr: "mistakes",
		label: "mistakes considered part of learning process",
		text: "Mistakes are considered part of the learning process at this school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn109: {
		type: "likert",
		key: "rn109",
		abbr: "mistakes",
		label: "mistakes considered part of learning process",
		text: "Mistakes are considered part of the learning process at this organisation.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	rn63: {
		type: "likert",
		key: "rn63",
		abbr: "social media",
		label: "talk about their social media profiles",
		text: "How often do you talk about the following with your eldest child at this school? Their social media profiles.",
		responses: ["Never", "Sometimes", "Occasionally", "Weekly", "Daily"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 2,
	},
	rn64: {
		type: "likert",
		key: "rn64",
		abbr: "browsing habits",
		label: "talk about what they are looking at online",
		text: "How often do you talk about the following with your eldest child at this school? What they are looking at online.",
		responses: ["Never", "Sometimes", "Weekly", "Daily"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn65: {
		type: "likert",
		key: "rn65",
		abbr: "passwords + security",
		label: "talk about passwords and security",
		text: "How often do you talk about the following with your eldest child at this school? Passwords and security.",
		responses: ["Never", "Sometimes", "Occassionally", "Weekly", "Daily"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 2,
	},
	rn66: {
		type: "likert",
		key: "rn66",
		abbr: "inappropriate content",
		label: "talk about inappropriate online content",
		text: "How often do you talk about the following with your eldest child at this school? Inappropriate online content.",
		responses: ["Never", "Sometimes", "Occasionally", "Weekly", "Daily"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 2,
	},
	rn67: {
		type: "likert",
		key: "rn67",
		abbr: "online bullying",
		label: "talk about online bullying",
		text: "How often do you talk about the following with your eldest child at this school? Online bullying of themselves or their friends.",
		responses: ["Never", "Sometimes", "Occasionally", "Weekly", "Daily"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 2,
	},
	rn68: {
		type: "likert",
		key: "rn68",
		abbr: "not being a bully",
		label: "talk about not being a bully",
		text: "How often do you talk about the following with your eldest child at this school? The importance of not being an online bully themselves.",
		responses: ["Never", "Sometimes", "Weekly", "Daily"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn69: {
		type: "likert",
		key: "rn69",
		abbr: "which games",
		label: "talk about online games they are playing",
		text: "How often do you talk about the following with your eldest child at this school? The online games they are playing.",
		responses: ["Never", "Sometimes", "Weekly", "Daily"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn70: {
		type: "likert",
		key: "rn70",
		abbr: "gaming time",
		label: "talk about time spent playing games",
		text: "How many hours each day does your child play these on-line games?",
		responses: ["I am not sure", "3+ hours", "1-2 hours", "Under 1 hour"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn83: {
		type: "likert",
		key: "rn83",
		abbr: "downloads",
		label: "Talk about their downloads.",
		text: "How often do you talk about this with your eldest child at this school? Their downloads.",
		responses: ["Never", "Sometimes", "Occasionally", "Weekly", "Daily"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 2,
	},
	rn84: {
		type: "likert",
		key: "rn84",
		abbr: "online games",
		label: "Do you know which online games your child plays?",
		text: "Do you know which online games your child plays?",
		responses: [
			"No",
			"Yes",
		],
		bins: [0, 1],
		yMin: 0,
		yMax: 1,
		threshold: 1
	},
	rn85: {
		type: "likert",
		key: "rn85",
		abbr: "sleep",
		label: "I am getting enough sleep.",
		text: "I am getting enough sleep.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},
	rn86: {
		type: "likert",
		key: "rn86",
		abbr: "happy",
		label: "I am feeling reasonably happy, taking everything into account.",
		text: "I am feeling reasonably happy, taking everything into account.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},
	rn87: {
		type: "likert",
		key: "rn87",
		abbr: "care",
		label: "Teachers here care about my child.",
		text: "Teachers here care about my child.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},
	rn88: {
		type: "likert",
		key: "rn88",
		abbr: "resources",
		label: "The school has the resources to achieve my child’s learning goals.",
		text: "The school has the resources to achieve my child’s learning goals.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},
	rn89: {
		type: "likert",
		key: "rn89",
		abbr: "overall",
		label: "Overall, the school is safe, encouraging and supportive.",
		text: "Overall, the school is safe, encouraging and supportive.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},
	rn54: {
		type: "likert",
		key: "rn54",
		abbr: "recommend",
		label: "I would recommend this school to my friends for their children.",
		text: "I would recommend this school to my friends for their children.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},
	rn55: {
		type: "likert",
		key: "rn55",
		abbr: "value alignment",
		label: "My values and the school’s values generally align. ",
		text: "My values and the school’s values generally align. ",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn56: {
		type: "likert",
		key: "rn56",
		abbr: "feel valued",
		label: "I feel valued by the school.",
		text: "I feel valued by the school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn57: {
		type: "likert",
		key: "rn57",
		abbr: "life balance",
		label: "I am able to reasonably balance all the demands on my time and energy.",
		text: "I am able to reasonably balance all the demands on my time and energy.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	rn206: {
		type: "likert",
		key: "rn206",
		abbr: "support",
		label: "I have family and good friends who support me as a parent.",
		text: "I have family and good friends who support me as a parent.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2
	},

	rn60_0: {
		type: "multi",
		key: "rn60",
		label: "None of these apply.",
		opt: "0",
	},
	rn60_1: {
		type: "multi",
		key: "rn60",
		label: "always feels safe",
		opt: "1",
	},
	rn60_2: {
		type: "multi",
		key: "rn60",
		label: "feels cared for and valued as a person",
		opt: "2",
	},
	rn60_3: {
		type: "multi",
		key: "rn60",
		label: "has made good friends at school",
		opt: "3",
	},
	rn60_4: {
		type: "multi",
		key: "rn60",
		label: "has recently been harassed, bullied or threatened at school, or on the way to school",
		opt: "4",
	},
	rn60_5: {
		type: "multi",
		key: "rn60",
		label: "is getting high quality education",
		opt: "6",
	},

	rn61_0: {
		type: "multi",
		key: "rn61",
		label: "None of these apply.",
		opt: "0",
	},
	rn61_1: {
		type: "multi",
		key: "rn61",
		label: "getting at least 8-9 hours sleep each night",
		opt: "1",
	},
	rn61_2: {
		type: "multi",
		key: "rn61",
		label: "eating well",
		opt: "2",
	},
	rn61_3: {
		type: "multi",
		key: "rn61",
		label: "exercising regularly",
		opt: "3",
	},
	rn61_4: {
		type: "multi",
		key: "rn61",
		label: "interested and involved in lots of things",
		opt: "4",
	},
	rn61_5: {
		type: "multi",
		key: "rn61",
		label: "often angry and/or difficult",
		opt: "5",
		flipped: true,
	},
	rn61_10: {
		type: "multi",
		key: "rn61",
		label: "getting time at home to chill out and relax",
		opt: "10",
	},

	rn62_0: {
		type: "multi",
		key: "rn62",
		label: "None of these apply.",
		opt: "0",
	},
	rn62_1: {
		type: "multi",
		key: "rn62",
		label: "enjoy spending time with each other",
		opt: "1",
	},
	rn62_2: {
		type: "multi",
		key: "rn62",
		label: "read and/or discuss books that interest them",
		opt: "2",
	},
	rn62_3: {
		type: "multi",
		key: "rn62",
		label: "don't argue about completing their homework",
		opt: "3",
		flipped: true,
	},
	rn62_4: {
		type: "multi",
		key: "rn62",
		label: "talk about how they are doing",
		opt: "4",
	},
	rn62_5: {
		type: "multi",
		key: "rn62",
		label: "include each other in family decisions",
		opt: "5",
	},
	rn62_6: {
		type: "multi",
		key: "rn62",
		label: "share meals around the table",
		opt: "6",
	},
	rn81_1: {
		type: "multi",
		key: "rn81",
		label: "knows I really love them, even when he or she makes mistakes",
		opt: "1",
	},
	rn81_2: {
		type: "multi",
		key: "rn81",
		label: "always treats me with respect",
		opt: "2",
	},

	rn71_0: {
		type: "multi",
		key: "rn71",
		label: "None of these apply.",
		opt: "0",
	},
	rn71_1: {
		type: "multi",
		key: "rn71",
		label: "set limits on screen time",
		opt: "1",
	},
	rn71_2: {
		type: "multi",
		key: "rn71",
		label: "filters in browser or search engines or social network sites",
		opt: "2",
	},
	rn71_3: {
		type: "multi",
		key: "rn71",
		label: "filters to block, limit or inform usage",
		opt: "3",
	},
	rn71_4: {
		type: "multi",
		key: "rn71",
		label: "privacy security filters",
		opt: "4",
	},
	rn71_5: {
		type: "multi",
		key: "rn71",
		label: "timetable internet use",
		opt: "5",
	},
	rn71_6: {
		type: "multi",
		key: "rn71",
		label: "control use of webcam",
		opt: "6",
	},
	rn71_7: {
		type: "multi",
		key: "rn71",
		label:
			"access to a log of your child’s internet use",
		opt: "7",
	},

	rn72_0: {
		type: "multi",
		key: "rn72",
		label: "None of these apply.",
		opt: "0",
	},
	rn72_1: {
		type: "multi",
		key: "rn72",
		label: "online bullying",
		opt: "1",
	},
	rn72_2: {
		type: "multi",
		key: "rn72",
		label: "sexual harassment/grooming",
		opt: "2",
	},
	rn72_3: {
		type: "multi",
		key: "rn72",
		label: "sexting",
		opt: "3",
	},
	rn72_4: {
		type: "multi",
		key: "rn72",
		label: "access to adult content",
		opt: "4",
	},
	rn72_5: {
		type: "multi",
		key: "rn72",
		label: "addictions, for example vaping",
		opt: "5",
	},
	rn72_6: {
		type: "multi",
		key: "rn72",
		label: "access to terror/radicalisation websites",
		opt: "6",
	},
	rn72_7: {
		type: "multi",
		key: "rn72",
		label: "illegal downloading of content",
		opt: "7",
	},
	rn72_8: {
		type: "multi",
		key: "rn72",
		label: "use of parent’s credit card",
		opt: "8",
	},
	rn72_9: {
		type: "multi",
		key: "rn72",
		label: "don't know what these risks are",
		opt: "9",
	},
	rn72_10: {
		type: "multi",
		key: "rn72",
		label: "know the risks but am not sure the extent of risk",
		opt: "10",
	},
	rn72_11: {
		type: "multi",
		key: "rn72",
		label: "know the risks and feel my child is safe",
		opt: "11",
	},
	rn72_12: {
		type: "multi",
		key: "rn72",
		label: "identity or financial theft",
		opt: "12",
	},

	rn73_0: {
		type: "multi",
		key: "rn73",
		label: "None of these apply.",
		opt: "0",
	},
	rn73_1: {
		type: "multi",
		key: "rn73",
		label: "confident my child is informed about prevention tools",
		opt: "1",
	},
	rn73_2: {
		type: "multi",
		key: "rn73",
		label: "feel I should be more involved in child's online activity",
		opt: "2",
	},
	rn73_3: {
		type: "multi",
		key: "rn73",
		label: "would like to attend courses regarding prevention",
		opt: "3",
	},
	rn73_4: {
		type: "multi",
		key: "rn73",
		label: "we share screen-time and online activities",
		opt: "4",
	},
	rn73_5: {
		type: "multi",
		key: "rn73",
		label: "together we set screen-time limits",
		opt: "5",
	},
	rn73_6: {
		type: "multi",
		key: "rn73",
		label: "together we set device free zones and times",
		opt: "6",
	},
	rn73_7: {
		type: "multi",
		key: "rn73",
		label: "I talk with my child about their screen use",
		opt: "7",
	},
	rn73_8: {
		type: "multi",
		key: "rn73",
		label: "I lead by example (I can put down my device too!)",
		opt: "8",
	},
	rn73_9: {
		type: "multi",
		key: "rn73",
		label: "I should be more involved in my child’s internet usage",
		opt: "9",
	},
	rn73_10: {
		type: "multi",
		key: "rn73",
		label: "I know what my child does on the internet",
		opt: "10",
	},
	rn73_11: {
		type: "multi",
		key: "rn73",
		label: "I am prepared to use prevention tools for my child",
		opt: "11",
	},
	rn73_12: {
		type: "multi",
		key: "rn73",
		label: "I would like to attend parent specific courses regarding prevention",
		opt: "12",
	},
	rn73_13: {
		type: "multi",
		key: "rn73",
		label: "I prohibit use behind closed doors",
		opt: "13",
	},
	rn73_14: {
		type: "multi",
		key: "rn73",
		label: "I prohibit use in bedrooms within one hour of lights out",
		opt: "14",
	},
	rn73_15: {
		type: "multi",
		key: "rn73",
		label: "I prohibit use in bedrooms after lights out",
		opt: "15",
	},
	rn73_16: {
		type: "multi",
		key: "rn73",
		label: "I feel it is more difficult to have meaningful time and talks with my child because of technology",
		opt: "16",
	},

	rn74_0: {
		type: "multi",
		key: "rn74",
		label: "None of these apply.",
		opt: "0",
	},
	rn74_1: {
		type: "multi",
		key: "rn74",
		label: "confident I can work out what to do if there is a problem",
		opt: "1",
	},
	rn74_2: {
		type: "multi",
		key: "rn74",
		label: "I have friends/relations I can talk to about problems",
		opt: "2",
	},
	rn74_3: {
		type: "multi",
		key: "rn74",
		label: "I know how to access to professional support",
		opt: "3",
	},
	rn74_4: {
		type: "multi",
		key: "rn74",
		label: "have few arguments with my child about technology",
		opt: "4",
		flipped: true,
	},
	rn74_5: {
		type: "multi",
		key: "rn74",
		label: "have established clear rules and routines for my child",
		opt: "5",
	},
	rn74_6: {
		type: "multi",
		key: "rn74",
		label: "confident that our family rules will be respected",
		opt: "6",
	},
	rn74_7: {
		type: "multi",
		key: "rn74",
		label: "feel that I am doing a good job as a parent",
		opt: "7",
	},
	rn74_8: {
		type: "multi",
		key: "rn74",
		label: "don't feel I need help to be a better parent",
		opt: "8",
		flipped: true,
	},
	rn74_9: {
		type: "multi",
		key: "rn74",
		label: "child has adult role models outside the family",
		opt: "9",
	},

	rn75_0: {
		type: "multi",
		key: "rn75",
		label: "None of these apply.",
		opt: "0",
	},
	rn75_1: {
		type: "multi",
		key: "rn75",
		label: "I feel positive about their future",
		opt: "1",
	},
	rn75_2: {
		type: "multi",
		key: "rn75",
		label: "I feel good about the way they usually behave",
		opt: "2",
	},
	rn75_3: {
		type: "multi",
		key: "rn75",
		label: "I know that they feel safe and secure",
		opt: "3",
	},
	rn75_4: {
		type: "multi",
		key: "rn75",
		label: "able to spend time with them doing everyday things",
		opt: "4",
	},
	rn75_5: {
		type: "multi",
		key: "rn75",
		label: "good at organising fun family times together",
		opt: "5",
	},
	rn75_6: {
		type: "multi",
		key: "rn75",
		label: "we share fun times with other families and friends",
		opt: "6",
	},
	rn75_7: {
		type: "multi",
		key: "rn75",
		label: "my child does well at school",
		opt: "7",
	},

	rn76_0: {
		type: "multi",
		key: "rn76",
		label: "None of these apply.",
		opt: "0",
	},
	rn76_1: {
		type: "multi",
		key: "rn76",
		label: "my values and school's values generally align",
		opt: "1",
	},
	rn76_2: {
		type: "multi",
		key: "rn76",
		label: "school has adequate funding",
		opt: "2",
	},
	rn76_3: {
		type: "multi",
		key: "rn76",
		label: "school provides a safe and caring environment",
		opt: "3",
	},
	rn76_4: {
		type: "multi",
		key: "rn76",
		label: "satisfied with school's communication about my child",
		opt: "4",
	},
	rn76_5: {
		type: "multi",
		key: "rn76",
		label: "school encourages feedback from parents/caregivers",
		opt: "5",
	},
	rn76_6: {
		type: "multi",
		key: "rn76",
		label: "would recommend this school to my friends",
		opt: "6",
	},
	rn76_7: {
		type: "multi",
		key: "rn76",
		label: "congratulate the school on commitment to wellbeing",
		opt: "7",
	},
	rn76_8: {
		type: "multi",
		key: "rn76",
		label: "child has at least one teacher who cares about them",
		opt: "8",
	},
	rn76_9: {
		type: "multi",
		key: "rn76",
		label: "child is getting high quality education",
		opt: "9",
	},
	rn82_0: {
		type: "multi",
		key: "rn82",
		label: "None of these apply.",
		opt: "0",
	},
	rn82_1: {
		type: "multi",
		key: "rn82",
		label: "encourages feedback from parents/caregivers",
		opt: "1",
	},
	rn82_2: {
		type: "multi",
		key: "rn82",
		label: "communicates well with me about my child",
		opt: "2",
	},
	rn82_3: {
		type: "multi",
		key: "rn82",
		label: "encourages parents/caregivers to be involved in the life of the school",
		opt: "3",
	},
	rn82_4: {
		type: "multi",
		key: "rn82",
		label: "communicates about its anti-bullying policy",
		opt: "4",
	},

	rn111_1: {
		type: "multi",
		key: "rn111",
		label: "age",
		opt: "1",
	},
	rn111_2: {
		type: "multi",
		key: "rn111",
		label: "disability",
		opt: "2",
	},
	rn111_3: {
		type: "multi",
		key: "rn111",
		label: "employment issue",
		opt: "3",
	},
	rn111_4: {
		type: "multi",
		key: "rn111",
		label: "gender",
		opt: "4",
	},
	rn111_5: {
		type: "multi",
		key: "rn111",
		label: "political belief",
		opt: "5",
	},
	rn111_6: {
		type: "multi",
		key: "rn111",
		label: "race",
		opt: "6",
	},
	rn111_7: {
		type: "multi",
		key: "rn111",
		label: "religious belief",
		opt: "7",
	},
	rn111_8: {
		type: "multi",
		key: "rn111",
		label: "sexual harrasment",
		opt: "8",
	},
	rn111_9: {
		type: "multi",
		key: "rn111",
		label: "victimisation",
		opt: "9",
	},
	rn111_10: {
		type: "multi",
		key: "rn111",
		label: "other",
		opt: "10",
	},

	ipe2: {
		type: "likert",
		key: "ipe2",
		abbr: "happy",
		label: "happy most of the time",
		text: "I am happy most of the time.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe3: {
		type: "likert",
		key: "ipe3",
		abbr: "have fun",
		label: "regularly have fun in my life",
		text: "I regularly have fun in my life.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe4: {
		type: "likert",
		key: "ipe4",
		abbr: "optimism",
		label: "think good things are going to happen",
		text: "I think good things are going to happen in my life.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe5: {
		type: "likert",
		key: "ipe5",
		abbr: "can learn",
		label: "can learn to be smarter",
		text: "I can learn to  be smarter.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe6: {
		type: "likert",
		key: "ipe6",
		abbr: "easy decisions",
		label: "find it easy to make decisions",
		text: "I find it easy to make decisions.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe7: {
		type: "likert",
		key: "ipe7",
		abbr: "keep trying",
		label: "keep trying even if I make mistakes",
		text: "I keep trying even if I make mistakes.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe8: {
		type: "likert",
		key: "ipe8",
		abbr: "proud",
		label: "am proud of my achievements",
		text: "I am proud of my achievements.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe9: {
		type: "likert",
		key: "ipe9",
		abbr: "flow",
		label: "lose track of time when doing something I enjoy at school",
		text: "I lose track of time when doing something I enjoy at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe10: {
		type: "likert",
		key: "ipe10",
		abbr: "try hard",
		label: "try hard at school",
		text: "I try hard at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe11: {
		type: "likert",
		key: "ipe11",
		abbr: "like learning",
		label: "like learning new things at school",
		text: "I like learning new things at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe12: {
		type: "likert",
		key: "ipe12",
		abbr: "like challenges",
		label: "like school work that challenges me",
		text: "I like school work that challenges me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe13: {
		type: "likert",
		key: "ipe13",
		abbr: "confident",
		label: "am confident I can do well at school",
		text: "I am confident I can do well at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe14: {
		type: "likert",
		key: "ipe14",
		abbr: "stress",
		label: "do not get stressed about my school work",
		text: "I get stressed about my school work.",
		responses: ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe15: {
		type: "likert",
		key: "ipe15",
		abbr: "teacher who cares",
		label: "have a teacher at my school who cares about me",
		text: "I have a teacher at my school who cares about me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe16: {
		type: "likert",
		key: "ipe16",
		abbr: "teachers who encourage",
		label: "have teachers who encourage me",
		text: "I have teachers who encourage me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe17: {
		type: "likert",
		key: "ipe17",
		abbr: "parents/caregivers who encourage",
		label: "have parents/caregivers who encourage me to do well",
		text: "I have parents or caregivers who encourage me to do well.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe18: {
		type: "likert",
		key: "ipe18",
		abbr: "parent who listens",
		label: "have a parent/carer who listens to me",
		text: "I have a parent or carer who listens to me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe19: {
		type: "likert",
		key: "ipe19",
		abbr: "keeping friends",
		label: "am good at keeping friends",
		text: "I am good at keeping friends.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe20: {
		type: "likert",
		key: "ipe20",
		abbr: "get along",
		label: "get along with people who are different from me",
		text: "I get along with people who are different from me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe21: {
		type: "likert",
		key: "ipe21",
		abbr: "safe at school",
		label: "feel safe at school",
		text: "I feel safe at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe22: {
		type: "likert",
		key: "ipe22",
		abbr: "belong at school",
		label: "feel like I belong at school",
		text: "I feel like I belong at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe23: {
		type: "likert",
		key: "ipe23",
		abbr: "adult can talk to",
		label: "have an adult I can talk to about my worries",
		text: "I have an adult in my life who I can talk to about my worries.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe24: {
		type: "likert",
		key: "ipe24",
		abbr: "respect diversity",
		label: "respect the beliefs/values of people who are different from me",
		text: "I respect the belifes and values of people who are different from me (e.g., religion, ethnicity, culture).",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe25: {
		type: "likert",
		key: "ipe25",
		abbr: "treated equally",
		label: "believe that everyone should be treated equally",
		text: "I believe that everyone should be treated equally.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe26: {
		type: "likert",
		key: "ipe26",
		abbr: "outside-school activity",
		label: "am involved with at least one activity outside of school",
		text: "I am involved with at least one activity outside of school such as a club, music, art, dance, sport, hobby or youth group.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe27: {
		type: "likert",
		key: "ipe27",
		abbr: "give time",
		label: "give my time to help others",
		text: "I give my time to help others.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe28: {
		type: "likert",
		key: "ipe28",
		abbr: "importance",
		label: "know I am important to others",
		text: "I know I am important to others.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe29: {
		type: "likert",
		key: "ipe29",
		abbr: "self-esteem",
		label: "like who I am",
		text: "I like who I am.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe30: {
		type: "likert",
		key: "ipe30",
		abbr: "direction",
		label: "feel my life has a sense of direction",
		text: "I feel my life has a sense of direction.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe31: {
		type: "likert",
		key: "ipe31",
		abbr: "using strengths",
		label: "am good at using my strengths in different ways",
		text: "I am good at using my strengths in different ways.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe32: {
		type: "likert",
		key: "ipe32",
		abbr: "aware of strengths",
		label: "am aware of the things I do well",
		text: "I am aware of the things I do well.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe33: {
		type: "likert",
		key: "ipe33",
		abbr: "stay calm",
		label: "stay calm when things go wrong",
		text: "I stay calm when things go wrong.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe34: {
		type: "likert",
		key: "ipe34",
		abbr: "think before act",
		label: "tend to think before I act",
		text: "I tend to think before I act.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe35: {
		type: "likert",
		key: "ipe35",
		abbr: "care",
		label: "care about other people's feelings",
		text: "I care about other people's feelings.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe36: {
		type: "likert",
		key: "ipe36",
		abbr: "stick to plans",
		label: "if I make a plan, I stick to it",
		text: "If I make a plan, I stick to it.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe37: {
		type: "likert",
		key: "ipe37",
		abbr: "extra-curricular",
		label:
			"engaging in extra-curricular activities at school is important to me",
		text: "Engaging in extra-curricular activities at school (e.g., music, sport, volunteer work) is...",
		responses: [
			"Not at all important to me",
			"Not very important to me",
			"Important to me",
			"Very important to me",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe38: {
		type: "likert",
		key: "ipe38",
		abbr: "community work",
		label: "doing things to improve my school community is important to me",
		text: "Doing things to make my school community a better place is...",
		responses: [
			"Not at all important to me",
			"Not very important to me",
			"Important to me",
			"Very important to me",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe39: {
		type: "likert",
		key: "ipe39",
		abbr: "healthy foods",
		label: "eat healthy foods nearly every day",
		text: "Over the last 2 last weeks, how often have you eaten healthy foods?",
		responses: [
			"Not at all",
			"Several days",
			"More than half the days",
			"Nearly every day",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	ipe40: {
		type: "likert",
		key: "ipe40",
		abbr: "eat breakfast",
		label: "eat breakfast on nearly every school day",
		text: "Over the last 2 last weeks, how often have you eaten breakfast on school days?",
		responses: [
			"Not at all",
			"Several days",
			"More than half the days",
			"Nearly every day",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	ipe41: {
		type: "likert",
		key: "ipe41",
		abbr: "exercise",
		label: "exercise for more than 30 minutes nearly every day",
		text: "Over the last 2 last weeks, how often have you exercised for more than 30 minutes? (e.g., running, playing sport)",
		responses: [
			"Not at all",
			"Several days",
			"More than half the days",
			"Nearly every day",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	ipe42: {
		type: "likert",
		key: "ipe42",
		abbr: "sleep",
		label: "have a good night's sleep nearly every night",
		text: "Over the last 2 last weeks, how often have you had a good night's sleep?",
		responses: [
			"Nearly every night",
			"More than half the nights",
			"Several nights",
			"Not at all",
		],
		bins: [4, 3, 2, 1],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe46: {
		type: "likert",
		key: "ipe46",
		abbr: "adult who cares",
		label: "in my boarding house, there is an adult who cares about me",
		text: "In my boarding house, there is an adult who cares about me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe47: {
		type: "likert",
		key: "ipe47",
		abbr: "adult who encourages",
		label: "in my boarding house, there is an adult who encourages me",
		text: "In my boarding house, there is an adult who encourages me to do well.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe48: {
		type: "likert",
		key: "ipe48",
		abbr: "feel safe",
		label: "in my boarding house, I feel safe",
		text: "In my boarding house, I feel safe.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe49: {
		type: "likert",
		key: "ipe49",
		abbr: "belonging",
		label: "in my boarding house, I feel like I belong",
		text: "In my boarding house, I feel like I belong.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe50: {
		type: "likert",
		key: "ipe50",
		abbr: "clear guidelines",
		label: "in my boarding house, there are clear behavioural guidelines",
		text: "In my boarding house, there are clear behavioural guidelines I am expected to follow.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe51: {
		type: "likert",
		key: "ipe51",
		abbr: "treated fairly",
		label:
			"in my boarding house, students treated fairly if they don't follow guidelines",
		text: "In my boarding house, students are treated fairly and appropriately if they don't follow the behavioural guidelines.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe52: {
		type: "likert",
		key: "ipe52",
		abbr: "good friend",
		label: "in my boarding ouse, I have a good friend who I can trust",
		text: "In my boarding house, I have a good friend who I can trust.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe53: {
		type: "likert",
		key: "ipe53",
		abbr: "missing home",
		label: "not bothered by really missing home",
		text: "Over the last 2 weeks, how often have you been bothered by really missing home?",
		responses: ["Nearly every day", "Most days", "Some days", "Not at all"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe54: {
		type: "likert",
		key: "ipe54",
		abbr: "empathy",
		label: "feel sad or upset when I see a person in need",
		text: "I feel sad or upset when I see a person in need (e.g., who is lonely, hurt, being picked on).",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe55: {
		type: "likert",
		key: "ipe55",
		abbr: "compassion",
		label: "always try to help when I see a person in need",
		text: "I always try to help when I see a person in need (e.g., who is lonely, hurt, being picked on).",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe56: {
		type: "likert",
		key: "ipe56",
		abbr: "gratitude",
		label: "grateful for many things in my life",
		text: "I am grateful for many things in my life.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe57: {
		type: "likert",
		key: "ipe57",
		abbr: "teamwork",
		label: "good at working in a group",
		text: "I am good at working in a group.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe58: {
		type: "likert",
		key: "ipe58",
		abbr: "creativity",
		label: "enjoy creating new things",
		text: "I enjoy creating new things.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe59: {
		type: "likert",
		key: "ipe59",
		abbr: "forgiveness",
		label: "forgive others who are mean to me",
		text: "I forgive others who are mean to me.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe60: {
		type: "likert",
		key: "ipe60",
		abbr: "self-forgiveness",
		label: "forgive myself when I make mistakes",
		text: "I forgive myself when I make mistakes.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe61: {
		type: "likert",
		key: "ipe61",
		abbr: "trustworthiness",
		label: "am trustworthy",
		text: "I am trustworthy.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe62: {
		type: "likert",
		key: "ipe62",
		abbr: "fairness",
		label: "treat people fairly",
		text: "I treat people fairly.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe63: {
		type: "likert",
		key: "ipe63",
		abbr: "courage",
		label: "always try to do the right thing even when it's hard",
		text: "I always try to do the right thing even when it's hard (e.g., stand up for others).",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe64: {
		type: "likert",
		key: "ipe64",
		abbr: "mindful of moment",
		label: "find it easy to pay attention to what's happening",
		text: "I find it easy to pay attention to what's happening in the moment.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe65: {
		type: "likert",
		key: "ipe65",
		abbr: "mindful of self",
		label: "tend to notice my thoughts and feelings",
		text: "I tend to notice my thoughts and feelings.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe66: {
		type: "likert",
		key: "ipe66",
		abbr: "social intelligence",
		label: "build trusting relationships with others",
		text: "I build trusting relationships with others.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	ipe67: {
		type: "likert",
		key: "ipe67",
		abbr: "anxious during change",
		label: "do not get anxious when things around me change",
		text: "I get anxious when things around me change.",
		responses: ["Strongly Agree", "Agree", "Disagree", "Strongly Disagree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe68: {
		type: "likert",
		key: "ipe68",
		abbr: "unexpected",
		label: "try to stay positive when unexpected happens",
		text: "I try to stay positive when something happens that I did not expect.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe69: {
		type: "likert",
		key: "ipe69",
		abbr: "taking action",
		label: "good at taking action when faced with uncertainty",
		text: "I am good at taking action when faced with uncertainty and change.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe70: {
		type: "likert",
		key: "ipe70",
		abbr: "changing demands",
		label: "find it easy to adjust to changing demands at school",
		text: "I find it easy to adjust to changing demands at school.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ipe71: {
		type: "likert",
		key: "ipe71",
		abbr: "trying new ways",
		label: "enjoy trying out new ways of doing things",
		text: "I enjoy trying out new ways of doing things.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	ry33: {
		type: "likert",
		key: "ry33",
		abbr: "enough sleep",
		label: "get enough sleep each night",
		text: "I get enough sleep each night.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry34: {
		type: "likert",
		key: "ry34",
		abbr: "feel sleepy",
		label: "feel sleepy during the day",
		text: "I feel sleepy during the day.",
		responses: [
			"Always or Almost Always",
			"Often",
			"Sometimes",
			"Never or Rarely",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry35: {
		type: "likert",
		key: "ry35",
		abbr: "healthy food",
		label: "eat healthy food",
		text: "I eat healthy food.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry36: {
		type: "likert",
		key: "ry36",
		abbr: "exercise",
		label: "physically active for one hour per day",
		text: "I am physically active for at least one hour each day.",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry37: {
		type: "likert",
		key: "ry37",
		abbr: "bullied",
		label: "not bullied at school",
		text: "In the last month, I have been bullied at school.",
		responses: ["Daily", "Weekly", "Once a month", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry38: {
		type: "likert",
		key: "ry38",
		abbr: "bullied online",
		label: "not bullied online",
		text: "In the last month, I have been bullied online.",
		responses: ["Daily", "Weekly", "Once a month", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry39: {
		type: "likert",
		key: "ry39",
		abbr: "excluded",
		label: "not socially excluded",
		text: "In the last month, I have been left out of a group of friends on purpose, ignored, or had rumours spread about me.",
		responses: ["Daily", "Weekly", "Once a month", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	ry40: {
		type: "likert",
		key: "ry40",
		abbr: "overnight device use",
		label: "not using a device between 10pm/midnight and 6am",
		text: "During the last week, how many times have you used a device between the hours of 10pm/midnight and 6am in the morning?",
		responses: ["5 or more times", "3 or 4 times", "Once or twice", "None"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	IINS: {
		type: "likert",
		key: "IINS",
		abbr: "Connection to Nature (IINS) [adapted]",
		label: "highly connected to nature (4 or 5 on 5-point scale)",
		text: "How connected are you with nature? (picture response)",
		responses: ["Option A", "Option B", "Option C", "Option D", "Option E"],
		bins: [1, 2, 3, 4, 5],
		yMin: 1,
		yMax: 5,
		threshold: 3,
	},
	ry42: {
		type: "likert",
		key: "ry42",
		abbr: "carry weapon",
		label: "have not carried a weapon to protect myself",
		text: "In the last month, I carried a weapon to protect myself.",
		responses: ["Daily", "Weekly", "Once a month", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},
	ry43: {
		type: "likert",
		key: "ry43",
		abbr: "threaten others",
		label: "have not threatened to physically hurt someone",
		text: "In the last month, I threatened to physically hurt someone.",
		responses: ["Daily", "Weekly", "Once a month", "Never"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 3,
	},

	jg1: {
		type: "likert",
		key: "jg1",
		abbr: "play outdoors (home)",
		label: "play outdoors when at home",
		text: "I play outdoors when at home.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg2: {
		type: "likert",
		key: "jg2",
		abbr: "play outdoors (here)",
		label: "play outdoors when I'm here",
		text: "I play outdoors when I'm here.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg3: {
		type: "likert",
		key: "jg3",
		abbr: "classwork outside",
		label: "like it when we do classwork outside",
		text: "I like it when we do classwork outside.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg4: {
		type: "likert",
		key: "jg4",
		abbr: "happy outside",
		label: "feel happy when I'm outside.",
		text: "I feel happy when I'm outside.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg5: {
		type: "likert",
		key: "jg5",
		abbr: "touching",
		label: "like touching animals and plants",
		text: "I like touching animals and plants.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg6: {
		type: "likert",
		key: "jg6",
		abbr: "like playing",
		label: "like playing outside",
		text: "I like playing outside.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg7: {
		type: "likert",
		key: "jg7",
		abbr: "being in nature",
		label: "feel that being in nature is important to me",
		text: "I feel that being in nature is important to me.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg8: {
		type: "likert",
		key: "jg8",
		abbr: "part of nature",
		label: "feel that I am part of nature",
		text: "I feel that I am part of nature.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg9: {
		type: "likert",
		key: "jg9",
		abbr: "live without",
		label: "don't think that we can live without plants and animals",
		text: "I don't think that we can live without plants and animals.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg10: {
		type: "likert",
		key: "jg10",
		abbr: "birds",
		label: "like listening to birds",
		text: "I like listening to birds.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg11: {
		type: "likert",
		key: "jg11",
		abbr: "plants + flowers",
		label: "like looking at plants and flowers",
		text: "I like looking at plants and flowers.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg12: {
		type: "likert",
		key: "jg12",
		abbr: "garden",
		label: "like working and playing in the garden",
		text: "I like working and playing in the garden.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg13: {
		type: "likert",
		key: "jg13",
		abbr: "rocks / shells",
		label: "like collecting rocks or shells",
		text: "I like collecting rocks or shells.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg14: {
		type: "likert",
		key: "jg14",
		abbr: "feel calm",
		label: "like being outside because it makes me feel calm",
		text: "I like being outside because it makes me feel calm.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg15: {
		type: "likert",
		key: "jg15",
		abbr: "beautiful",
		label: "think nature is beautiful",
		text: "I think nature is beautiful.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg16: {
		type: "likert",
		key: "jg16",
		abbr: "amazing",
		label: "feel that nature is amazing",
		text: "I feel that nature is amazing.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg17: {
		type: "likert",
		key: "jg17",
		abbr: "empathy",
		label: "feel sad when animals are hurt",
		text: "I feel sad when animals are hurt.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg18: {
		type: "likert",
		key: "jg18",
		abbr: "looking after",
		label: "feel that looking after plants and animals is important",
		text: "I feel that looking after plants and animals is important.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg19: {
		type: "likert",
		key: "jg19",
		abbr: "clean home",
		label: "feel happy when animals have a clean home",
		text: "I feel happy when animals have a clean home.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg20: {
		type: "likert",
		key: "jg20",
		abbr: "help nature",
		label: "do things to help nature",
		text: "I do things to help nature.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg21: {
		type: "likert",
		key: "jg21",
		abbr: "pick up rubbish",
		label: "pick up rubbish",
		text: "I pick up rubbish.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg22: {
		type: "likert",
		key: "jg22",
		abbr: "try not to hurt",
		label: "try not to hurt plants and animals",
		text: "I try not to hurt plants and animals.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg23: {
		type: "likert",
		key: "jg23",
		abbr: "turn of lights",
		label: "turn off the lights at home",
		text: "I turn off the lights at home.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg24: {
		type: "likert",
		key: "jg24",
		abbr: "recycling",
		label: "help with the recycling at home",
		text: "I help with the recycling at home.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg25: {
		type: "likert",
		key: "jg25",
		abbr: "water",
		label: "try not to waste water",
		text: "I try not to waste water.",
		responses: ["Hardly ever", "Sometimes", "Always"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},

	jg28: {
		type: "likert",
		key: "jg28",
		abbr: "family love",
		label: "know my family loves me",
		text: "I know my family loves me.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg29: {
		type: "likert",
		key: "jg29",
		abbr: "try hard",
		label: "try hard when I'm here",
		text: "I try hard when I'm here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg30: {
		type: "likert",
		key: "jg30",
		abbr: "like coming",
		label: "like coming here",
		text: "I like coming here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg31: {
		type: "likert",
		key: "jg31",
		abbr: "enjoy learning",
		label: "enjoy learning new things here",
		text: "I enjoy learning new things here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg32: {
		type: "likert",
		key: "jg32",
		abbr: "happy",
		label: "am happy here",
		text: "I am happy here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg33: {
		type: "likert",
		key: "jg33",
		abbr: "fun",
		label: "have fun here",
		text: "I have fun here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg34: {
		type: "likert",
		key: "jg34",
		abbr: "good sleep",
		label: "had a good sleep last night",
		text: "I had a good sleep last night.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg35: {
		type: "likert",
		key: "jg35",
		abbr: "feel sleepy",
		label: "feel sleepy during the day",
		text: "I feel sleepy during the day.",
		responses: ["Yes", "Sometimes", "No"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg36: {
		type: "likert",
		key: "jg36",
		abbr: "worry",
		label: "don't worry a lot",
		text: "I worry a lot.",
		responses: ["Yes", "Sometimes", "no"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg37: {
		type: "likert",
		key: "jg37",
		abbr: "sad",
		label: "don't often feel sad",
		text: "I often feel sad.",
		responses: ["Yes", "Sometimes", "No"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg38: {
		type: "likert",
		key: "jg38",
		abbr: "different things",
		label: "like to do lots of different things",
		text: "I like to do lots of different things.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg39: {
		type: "likert",
		key: "jg39",
		abbr: "good friend",
		label: "have a good friend here",
		text: "I have a good friend here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg40: {
		type: "likert",
		key: "jg40",
		abbr: "feel safe",
		label: "I feel safe at school.",
		text: "I feel safe at school.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg50: {
		type: "likert",
		key: "jg50",
		abbr: "play outdoors (home)",
		label: "play outdoors when at home",
		text: "I play outdoors when at home.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg51: {
		type: "likert",
		key: "jg51",
		abbr: "play outdoors (here)",
		label: "play outdoors when I'm here",
		text: "I play outdoors when I'm here.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg52: {
		type: "likert",
		key: "jg52",
		abbr: "classwork",
		label: "like it when we do classwork outside",
		text: "I like it when we do classwork outside.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg53: {
		type: "likert",
		key: "jg53",
		abbr: "feel happy",
		label: "feel happy when I'm outside",
		text: "I feel happy when I'm outside.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg54: {
		type: "likert",
		key: "jg54",
		abbr: "touching",
		label: "like touching animals and plants",
		text: "I like touching animals and plants.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg55: {
		type: "likert",
		key: "jg55",
		abbr: "playing outside",
		label: "like playing outside",
		text: "I like playing outside.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg56: {
		type: "likert",
		key: "jg56",
		abbr: "birds",
		label: "like listening to birds",
		text: "I like listening to birds.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg57: {
		type: "likert",
		key: "jg57",
		abbr: "plants + flowers",
		label: "like looking at plants and flowers",
		text: "I like looking at plants and flowers.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg58: {
		type: "likert",
		key: "jg58",
		abbr: "garden",
		label: "like working and playing in the garden",
		text: "I like working and playing in the garden.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg59: {
		type: "likert",
		key: "jg59",
		abbr: "rocks / shells",
		label: "like collecting rocks or shells",
		text: "I like collecting rocks or shells.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg60: {
		type: "likert",
		key: "jg60",
		abbr: "feel calm",
		label: "like being outside because it makes me feel calm",
		text: "I like being outside because it makes me feel calm.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg61: {
		type: "likert",
		key: "jg61",
		abbr: "empathy",
		label: "feel sad when animals are hurt",
		text: "I feel sad when animals are hurt.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg62: {
		type: "likert",
		key: "jg62",
		abbr: "looking after",
		label: "think that looking after plants and animals is important",
		text: "I think that looking after plants and animals is important.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg63: {
		type: "likert",
		key: "jg63",
		abbr: "clean home",
		label: "feel happy when animals have a clean home",
		text: "I feel happy when animals have a clean home.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg64: {
		type: "likert",
		key: "jg64",
		abbr: "rubbish",
		label: "pick up rubbish",
		text: "I pick up rubbish.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg65: {
		type: "likert",
		key: "jg65",
		abbr: "try not to hurt",
		label: "try not to hurt plants and animals",
		text: "I try not to hurt plants and animals.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg66: {
		type: "likert",
		key: "jg66",
		abbr: "lights",
		label: "turn off the lights at home when they are not needed",
		text: "I turn off the lights at home when they are not needed.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg67: {
		type: "likert",
		key: "jg67",
		abbr: "recycling",
		label: "help with the recycling at home",
		text: "I help with the recycling at home.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},
	jg68: {
		type: "likert",
		key: "jg68",
		abbr: "water",
		label: "try not to waste water",
		text: "I try not to waste water.",
		responses: ["No", "Sometimes", "Yes"],
		bins: [1, 2, 3],
		yMin: 1,
		yMax: 3,
		threshold: 1,
	},

	jg100: {
		type: "likert",
		key: "jg100",
		abbr: "classtime outdoors",
		label: "students often spend class time outdoors with me",
		text: "How much class time do your students spend outdoors with you?",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	jg101: {
		type: "likert",
		key: "jg101",
		abbr: "play structures",
		label: "installed play structures",
		text: "Installed play structures.",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg102: {
		type: "likert",
		key: "jg102",
		abbr: "open grass",
		label: "open grassland (e.g. field, oval)",
		text: "Open grassland (e.g. field, oval).",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg103: {
		type: "likert",
		key: "jg103",
		abbr: "open hard-cover",
		label:
			"open hard-covered area (e.g. rubber, concrete, asphalt, sports court)",
		text: "Open hard-covered area (e.g. rubber, concrete, asphalt, sports court).",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg104: {
		type: "likert",
		key: "jg104",
		abbr: "other open",
		label:
			"open area with other natural material (e.g. bark, wood chips, sand)",
		text: "Open area with other natural material (e.g. bark, wood chips, sand).",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg105: {
		type: "likert",
		key: "jg105",
		abbr: "play structures",
		label:
			"natural features (e.g. trees, logs, boulders, branches, creek or pond)",
		text: "Natural features (e.g. trees, logs, boulders, branches, creek or pond).",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg106: {
		type: "likert",
		key: "jg106",
		abbr: "bush / forest",
		label: "bushland or forest",
		text: "Bushland or forest.",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg107: {
		type: "likert",
		key: "jg107",
		abbr: "gardens",
		label: "gardens",
		text: "Gardens.",
		responses: ["None", "Very few", "Some", "A lot"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	jg108: {
		type: "likert",
		key: "jg108",
		abbr: "oneness",
		label: "often feel a sense of oneness with the natural world around me",
		text: "I often feel a sense of oneness with the natural world around me.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg109: {
		type: "likert",
		key: "jg109",
		abbr: "community",
		label: "think of the natural world as a community to which I belong",
		text: "I think of the natural world as a community to which I belong.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg110: {
		type: "likert",
		key: "jg110",
		abbr: "intelligence",
		label:
			"recognise and appreciate the intelligence of other living organisms",
		text: "I recognise and appreciate the intelligence of other living organisms.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg111: {
		type: "likert",
		key: "jg111",
		abbr: "connection",
		label: "usually feel connected to nature",
		text: "I often feel disconnected from nature.",
		responses: ["Strongly agree", "Agree", "Disagree", "Strongly disagree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg112: {
		type: "likert",
		key: "jg112",
		abbr: "cyclical process",
		label: "I imagine myself to be part of a larger cyclical process of living",
		text: "When I think of my life, I imagine myself to be part of a larger cyclical process of living.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg113: {
		type: "likert",
		key: "jg113",
		abbr: "kinship",
		label: "often feel a kinship with animals and plants",
		text: "I often feel a kinship with animals and plants.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg114: {
		type: "likert",
		key: "jg114",
		abbr: "belonging",
		label:
			"feel as though I belong to the Earth as equally as it belongs to me",
		text: "I feel as though I belong to the Earth as equally as it belongs to me.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg115: {
		type: "likert",
		key: "jg115",
		abbr: "actions",
		label:
			"have a deep understanding of how my actions affect the natural world",
		text: "I have a deep understanding of how my actions affect the natural world.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg116: {
		type: "likert",
		key: "jg116",
		abbr: "web of life",
		label: "often feel part of the web of life",
		text: "I often feel part of the web of life.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg117: {
		type: "likert",
		key: "jg117",
		abbr: "life force",
		label: "feel all inhabitants of Earth share a common ‘life force’",
		text: "I feel that all inhabitants of Earth, human, and nonhuman, share a common ‘life force’.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg118: {
		type: "likert",
		key: "jg118",
		abbr: "embedded",
		label: "feel embedded within the broader natural world",
		text: "Like a tree can be part of a forest, I feel embedded within the broader natural world.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg119: {
		type: "likert",
		key: "jg119",
		abbr: "heirarchy",
		label: "don't consider myself at top of a hierarchy that exists in nature",
		text: "When I think of my place on Earth, I consider myself to be a top member of a hierarchy that exists in nature.",
		responses: ["Strongly agree", "Agree", "Disagree", "Strongly disagree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg120: {
		type: "likert",
		key: "jg120",
		abbr: "insignificance",
		label: "feel like I am only a small part of the natural world around me",
		text: "I often feel like I am only a small part of the natural world around me, and that I am no more important than the grass on the ground or the birds in the trees.",
		responses: ["Strongly disagree", "Disagree", "Agree", "Strongly agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	jg121: {
		type: "likert",
		key: "jg121",
		abbr: "dependence",
		label: "my personal welfare depends on the welfare of the natural world",
		text: "My personal welfare is independent of the welfare of the natural world.",
		responses: ["Strongly agree", "Agree", "Disagree", "Strongly disagree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},

	jg122: {
		type: "likert",
		key: "jg122",
		abbr: "Teacher Guide",
		label: "The Teacher Guide.",
		text: "The Teacher Guide.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg123: {
		type: "likert",
		key: "jg123",
		abbr: "Action Plan",
		label: "Action Plan document.",
		text: "Action Plan document.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg124: {
		type: "likert",
		key: "jg124",
		abbr: "A Greener Earth",
		label: "Lesson Plan - A Greener Earth.",
		text: "Lesson Plan - A Greener Earth.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg125: {
		type: "likert",
		key: "jg125",
		abbr: "Australian Habitats",
		label: "Lesson Plan - Australian Habitats.",
		text: "Lesson Plan - Australian Habitats.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg126: {
		type: "likert",
		key: "jg126",
		abbr: "Seeds of Change",
		label: "Lesson Plan - Seeds of Change.",
		text: "Lesson Plan - Seeds of Change.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg127: {
		type: "likert",
		key: "jg127",
		abbr: "Australia's Amazing Wildlife",
		label: "Lesson Plan - Australia's Amazing Wildlife.",
		text: "Lesson Plan - Australia's Amazing Wildlife.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg128: {
		type: "likert",
		key: "jg128",
		abbr: "Biodiversity Project",
		label:
			"Lesson Plan - Design and Implement a Biodiversity Conservation Project.",
		text: "Lesson Plan - Design and Implement a Biodiversity Conservation Project.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg129: {
		type: "likert",
		key: "jg129",
		abbr: "Time to Act",
		label: "Lesson Plan - It's Time to Act Now.",
		text: "Lesson Plan - It's Time to Act Now.",
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg130: {
		type: "likert",
		key: "jg130",
		abbr: "Local Safari",
		label: '"Local Safari" book from the Resource box.',
		text: '"Local Safari" book from the Resource box.',
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg131: {
		type: "likert",
		key: "jg131",
		abbr: "Amazing Australia",
		label: '"Amazing Australia" - book from the Resource box.',
		text: '"Amazing Australia" - book from the Resource box.',
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg132: {
		type: "likert",
		key: "jg132",
		abbr: "What on Earth?",
		label: '"What on Earth?" - book from the Resource box.',
		text: '"What on Earth?" - book from the Resource box.',
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	jg133: {
		type: "likert",
		key: "jg133",
		abbr: "Incredible Earth",
		label: '"Incredible Earth" - book from the Resource box.',
		text: '"Incredible Earth" - book from the Resource box.',
		responses: [
			"I did not use this resource",
			"Not at all helpful",
			"Somewhat helpful",
			"Very helpful",
			"Extremely helpful",
		],
		bins: [0, 1, 2, 3, 4],
		yMin: 0,
		yMax: 4,
		threshold: 2,
	},
	k1: {
		type: "likert",
		key: "k1",
		abbr: "Calm",
		label: "I am a calm person.",
		text: "I am a calm person.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k2: {
		type: "likert",
		key: "k2",
		abbr: "Grateful - Self",
		label: "I feel grateful every day.",
		text: "I feel grateful every day.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k3: {
		type: "likert",
		key: "k3",
		abbr: "Grateful - Others",
		label: "I am thankful for the people in my life.",
		text: "I am thankful for the people in my life.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k4: {
		type: "likert",
		key: "k4",
		abbr: "Engaged - Others",
		label: "I am interested in what family and friends are doing.",
		text: "I am interested in what family and friends are doing.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k5: {
		type: "likert",
		key: "k5",
		abbr: "Caring - Self",
		label: "I do nice things for myself.",
		text: "I do nice things for myself.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k6: {
		type: "likert",
		key: "k6",
		abbr: "Caring - Others",
		label: "I am kind to people, animals and the environment.",
		text: "I am kind to people, animals and the environment.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k7: {
		type: "likert",
		key: "k7",
		abbr: "Inclusive - Self",
		label: "I try to include others in things I am doing.",
		text: "I try to include others in things I am doing.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k8: {
		type: "likert",
		key: "k8",
		abbr: "Aware - Self",
		label: "Being kind to myself is important.",
		text: "Being kind to myself is important.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k9: {
		type: "likert",
		key: "k9",
		abbr: "Aware - Others",
		label: "I care about people's feelings.",
		text: "I care about people's feelings.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k10: {
		type: "likert",
		key: "k10",
		abbr: "Brave - Others",
		label: "I take responsibility for my behaviour towards others.",
		text: "I take responsibility for my behaviour towards others.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k11: {
		type: "likert",
		key: "k11",
		abbr: "Trustworthy - Self",
		label: "I am an honest person.",
		text: "I am an honest person.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k12: {
		type: "likert",
		key: "k12",
		abbr: "trust",
		label: "trust my friends and they trust me",
		text: "trust my friends and they trust me",
		responses: [
			"Never or Rarely",
			"Sometimes",
			"Often",
			"Always or Almost Always",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k19: {
		type: "likert",
		key: "k19",
		abbr: "Engaged - Self",
		label: "I have a hobby or interest that I enjoy.",
		text: "I have a hobby or interest that I enjoy.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	k21: {
		type: "likert",
		key: "k21",
		abbr: "Righteousness",
		label: "I always try to do the right thing even when it's hard.",
		text: "I always try to do the right thing even when it's hard.",
		responses: ["Strongly Disagree", "Disagree", "Agree", "Strongly Agree"],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	swat1: {
		type: "likert",
		key: "swat1",
		abbr: "Learing & Wellbeing",
		label: "I believe wellbeing and learning are closely linked.",
		text: "I believe wellbeing and learning are closely linked.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat2: {
		type: "likert",
		key: "swat2",
		abbr: "Learing & Wellbeing",
		label: "I intentionally teach my students about the importance of wellbeing.",
		text: "I intentionally teach my students about the importance of wellbeing.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat3: {
		type: "likert",
		key: "swat3",
		abbr: "Relationship Quality",
		label: "I prioritise quality relationships with my students.",
		text: "I prioritise quality relationships with my students.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat4: {
		type: "likert",
		key: "swat4",
		abbr: "Learning Wellbeing",
		label: "I spend time learning about student wellbeing.",
		text: "I spend time learning about student wellbeing.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat5: {
		type: "likert",
		key: "swat5",
		abbr: "Fostering Wellbeing",
		label: "Our school is committed to fostering the wellbeing of each student.",
		text: "Our school is committed to fostering the wellbeing of each student.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat6: {
		type: "likert",
		key: "swat6",
		abbr: "Explicit Approach.",
		label: "Our school implements an explicit wellbeing approach.",
		text: "Our school implements an explicit wellbeing approach.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat7: {
		type: "likert",
		key: "swat7",
		abbr: "Positive Impact Belief",
		label: "All staff believe that they can make a positive difference to student wellbeing.",
		text: "All staff believe that they can make a positive difference to student wellbeing.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat8: {
		type: "likert",
		key: "swat8",
		abbr: "Inclusiveness & Safety",
		label: "Our school is an inclusive and safe environment for all students.",
		text: "Our school is an inclusive and safe environment for all students.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat9: {
		type: "likert",
		key: "swat9",
		abbr: "Mental Health Check",
		label: "Student mental health and wellbeing is a regular focus of discussion in our staff meetings.",
		text: "Student mental health and wellbeing is a regular focus of discussion in our staff meetings.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat10: {
		type: "likert",
		key: "swat10",
		abbr: "Professional Learning",
		label: "Our school provides wellbeing focused Professional Learning.",
		text: "Our school provides wellbeing focused Professional Learning.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat11: {
		type: "likert",
		key: "swat11",
		abbr: "Communicates Wellbeing",
		label: "Our school communicates wellbeing data to students, staff, and parents/caregivers.",
		text: "Our school communicates wellbeing data to students, staff, and parents/caregivers.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat12: {
		type: "likert",
		key: "swat12",
		abbr: "Staff Wellbeing",
		label: "Our school leadership prioritises staff wellbeing.",
		text: "Our school leadership prioritises staff wellbeing.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat13: {
		type: "likert",
		key: "swat13",
		abbr: "Integrate Wellbeing",
		label: "Every teacher integrates wellbeing skills into their classroom teaching.",
		text: "Every teacher integrates wellbeing skills into their classroom teaching.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat14: {
		type: "likert",
		key: "swat14",
		abbr: "Engage Family",
		label: "Our school engages parents/caregivers in supporting student wellbeing.",
		text: "Our school engages parents/caregivers in supporting student wellbeing.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	swat15: {
		type: "likert",
		key: "swat15",
		abbr: "Opinions & Perspectives",
		label: "Our school actively seeks the opinions and perspectives of our students to inform the wellbeing program.",
		text: "Our school actively seeks the opinions and perspectives of our students to inform the wellbeing program.",
		responses: ["Largely true", "Partly true", "Largely not true"],
		bins: [3, 2, 1],
		yMin: 1,
		yMax: 3,
		threshold: 2
	},
	sm1: {
		type: "likert",
		key: "sm1",
		abbr: "screen-time at school",
		label: "use screens at school (e.g. computer, TV, phone, game console) 3 or more times a day",
		text: "How often do you use screens at school (e.g. computer, TV, phone, game console)?",
		responses: [
			"Never",
			"Up to 2 hours each day",
			"2-4 hours each day",
			"More than 4 hours each day",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sm2: {
		type: "likert",
		key: "sm2",
		abbr: "screen-time at home",
		label: "use screens at home (e.g. computer, TV, phone, game console) 3 or more times a day",
		text: "How often do you use screens at home (e.g. computer, TV, phone, game console)?",
		responses: [
			"Never",
			"Up to 2 hours each day",
			"2-4 hours each day",
			"More than 4 hours each day",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sm3: {
		type: "likert",
		key: "sm3",
		abbr: "social media screen-time",
		label: "use social media apps (e.g. TikTok, Instagram, YouTube) 3 or more times a day",
		text: "How often do you use social media apps (e.g. TikTok, Instagram, YouTube, Snapchat)?",
		responses: [
			"Never",
			"Up to 2 hours each day",
			"2-4 hours each day",
			"More than 4 hours each day",
		],
		bins: [1, 2, 3, 4],
		yMin: 1,
		yMax: 4,
		threshold: 2,
	},
	sm4_1: {
		type: "multi",
		key: "sm4",
		label: "chatting with friends and family",
		opt: "1",
	},
	sm4_2: {
		type: "multi",
		key: "sm4",
		label: "watching videos or live streams",
		opt: "2",
	},
	sm4_3: {
		type: "multi",
		key: "sm4",
		label: "playing games or using game-related features",
		opt: "3",
	},
	sm4_4: {
		type: "multi",
		key: "sm4",
		label: "sharing photos or videos with others",
		opt: "4",
	},
	sm4_5: {
		type: "multi",
		key: "sm4",
		label: "learning new things",
		opt: "5",
	},
	sm4_6: {
		type: "multi",
		key: "sm4",
		label: "following celebrities, influencers, or public figures",
		opt: "6",
	},
	sm4_7: {
		type: "multi",
		key: "sm4",
		label: "exploring groups or communities that match my interests",
		opt: "7",
	},
	sm4_8: {
		type: "multi",
		key: "sm4",
		label: "finding or joining events",
		opt: "8",
	},
	sm4_9: {
		type: "multi",
		key: "sm4",
		label: "shopping or looking at products",
		opt: "9",
	},
};
